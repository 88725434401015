import React from "react";
import { Col, Tab } from "react-bootstrap";
import Heading from "../../../components/shared/Heading";
import { Styles } from "../styles/sampleExamTab";
import { useTranslation } from "react-i18next";

export const SampleDocument = ({ title, url, buttonText = "Download" }) => {
  const { t } = useTranslation();

  return (
    <Styles>
      <div className="sample-item">
        <h6 className="sample-title">{t(title)}</h6>
        <a href={url} target="_blank" className="download-button">
          {t(buttonText)}
        </a>
      </div>
    </Styles>
  );
};

const CourseDetailsSampleExamTab = ({ data }) => {
  return (
    <Tab.Pane eventKey="Exam Materials">
      <Col md="12">
        <Heading tag="h4" className="strategy-title" title={"Materials"} />
        <SampleDocument
          title={"Scholarship Exam Sample (2024)"}
          url={data.questions}
        />
        <SampleDocument
          title={"Scholarship Exam Sample (2024) - Answers"}
          url={data.answers}
        />
        <SampleDocument
          title={"Scholarship Exam Demo Version"}
          url={data.demo}
          buttonText="Start"
        />
      </Col>
    </Tab.Pane>
  );
};

export default CourseDetailsSampleExamTab;
