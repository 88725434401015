import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import BreadcrumbBox from "../components/common/Breadcrumb";
import AccordionItem from "./components/reusableComponents/AccordionItem";

import { useCurrentLanguage } from "../hooks/useCurrentLanguage";

import { Styles } from "./styles/faqs.js";

import Data from "./data/faq.json";

const FAQsPage = ({ className, variation, ...props }) => {
  const { t } = useTranslation();
  const [language] = useCurrentLanguage();
  const [isOpen, setIsOpen] = useState(1);

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen(0);
    } else {
      setIsOpen(param);
    }
  };

  return (
    <Styles>
      <div className="main-wrapper faqs-page">
        <BreadcrumbBox
          title="Frequently Asked Questions"
          image="/assets/images/faq.jpg"
        />
        <section className="faqs-area">
          <Container>
            <Row className="accordion">
              <Col md="12" className="faq-description">
                <p>
                  {t(
                    "Got a question? Can't find the answer you're looking for? Don't worry, drop us a line on our"
                  )}
                  <a href="/contact">{t("Contact page.")}</a>
                </p>
              </Col>
              {Data?.map((data) => (
                <AccordionItem
                  key={data.id}
                  id={data.id}
                  title={data.title?.[language]}
                  textContent={data?.description?.[language]}
                  isOpen={isOpen}
                  onClick={() => toggleCollapse(data.id)}
                />
              ))}
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default FAQsPage;
