import React from "react";
import Datas from "../data/partner/partner.json";
import { Container, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { Styles } from "./styles/teamSlider.js";
import Heading from "./shared/Heading";

const PartnersSlider = () => {
  const settings = {
    slidesPerView: 4,
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    spaceBetween: 50,
    clickable: true,
    watchSlidesVisibility: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <Styles>
      {/* Team Slider */}
      <section className="team-member-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <Heading tag="h4" title={Datas?.sliderTitle} />
              </div>
            </Col>
            <Col md="12" className="team-slider">
              <Swiper {...settings} className={"d-flex align-items-center"}>
                {Datas.dataList.map((data, i) => (
                  <a
                    href={data.partnerLink}
                    target="_blank"
                    className="team-item"
                    key={i}
                  >
                    <img
                      src={`/assets/images/${data?.partnerImage}`}
                      alt={data?.partnerName}
                      className="img-fluid"
                    />
                  </a>
                ))}
              </Swiper>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default PartnersSlider;
