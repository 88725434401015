import React from "react";
import { Tab } from "react-bootstrap";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";

const CourseDetailsRequirementsTab = ({ data }) => {
  return (
    <Tab.Pane eventKey="Requirements" className="overview-tab">
      {data?.map((requirement, index) => (
        <div className="course-desc" key={index}>
          <h5>{requirement?.title}</h5>
          {typeof requirement?.description === "string" ? (
            <p>{requirement?.description}</p>
          ) : (
            <>
              {requirement?.description?.map((req, index) => (
                <React.Fragment key={index}>
                  {index === 0 ? (
                    <div className="course-desc-container-title d-flex">
                      <p>{req}</p>
                    </div>
                  ) : (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.02 }}
                      className="course-desc-container d-flex"
                    >
                      <div>
                        <Icon
                          icon="ph:paper-plane-right-duotone"
                          fontSize={24}
                          className="pt-1"
                          color={colors.bg1}
                        />
                      </div>
                      <p>{req}</p>
                    </motion.div>
                  )}
                </React.Fragment>
              ))}
            </>
          )}
        </div>
      ))}
    </Tab.Pane>
  );
};

export default CourseDetailsRequirementsTab;
