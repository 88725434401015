import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "./shared/Button";
import DisplayHOC from "./hocs/DisplayHOC";

export const NewFooterBanner = ({ data }) => {
  const navigate = useNavigate();

  return (
    <DisplayHOC>
      <div className="new-banner">
        <div className="main-wrapper">
          <h2>{data.title}</h2>
          <h3>{data.postTitle}</h3>
        </div>
        <Button
          className={"apply-button"}
          text={data.linkText}
          onClick={() => navigate(data.linkHref)}
        ></Button>
      </div>
    </DisplayHOC>
  );
};
