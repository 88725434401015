import { isNull, isUndefined } from "lodash";
import { getNews, getNewsById } from "../repository/newsRepository";

export default {
  getNews: async ({ currentPage, pageSize, isLatestNews, isAtTop }) => {
    const pageNumber =
      isNull(currentPage) || isUndefined(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isNull(pageSize) || isUndefined(pageSize) ? 20 : pageSize;

    if (isLatestNews) {
      return await getNews({
        "is-latest": isLatestNews,
        "current-page": pageNumber,
        "page-size": itemsPerPage,
      });
    }

    if (isAtTop) {
      return await getNews({
        "current-page": pageNumber,
        "page-size": itemsPerPage,
        "is-at-top": isAtTop,
      });
    }

    return await getNews({
      "current-page": pageNumber,
      "page-size": itemsPerPage,
    });
  },

  getNewsById: async (id) => await getNewsById(id),
};
