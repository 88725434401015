import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BackToTop from "./common/BackToTop";
import SocialMediaComponent from "./shared/SocialMediaComponent";
import NavigationLink from "./shared/NavigationLink";

import { Styles } from "./styles/footer.js";
import Heading from "./shared/Heading";
import { colors } from "./common/element/elements";
import { Icon } from "@iconify/react";

import BannerData from "../data/new-banner/banner.json";
import BannerDataUz from "../data/new-banner/banner-uz.json";
import BannerDataRu from "../data/new-banner/banner-ru.json";
import { useLanguageDetector } from "../hooks/useLanguageDetector.js";
import { NewFooterBanner } from "./NewFooterBanner.jsx";

const Footer = () => {
  const { t } = useTranslation();

  const [bannerData] = useLanguageDetector(
    BannerData,
    BannerDataRu,
    BannerDataUz
  );

  return (
    <Styles>
      <NewFooterBanner data={bannerData[0]} />
      <footer className="footer2">
        <Container>
          <Row>
            <Col xl="5" lg="7">
              <div className="footer-logo-info">
                <Link to={"/"}>
                  <img
                    src={"/assets/images/logo-white.png"}
                    alt=""
                    className="img-fluid"
                  />
                </Link>
                <p>
                  {t(
                    "A New Research Based University with Science and Technology Integrated Learning"
                  )}
                </p>
                <ul className="list-unstyled">
                  <li>
                    <Icon
                      color={colors.bg2}
                      icon="ph:map-pin-duotone"
                      fontSize={24}
                      className="mr-3"
                    />
                    <a
                      href={`https://maps.app.goo.gl/FA5i5AFK3BSJyeng9`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>
                        {t(
                          "Administrative building, Tashkent Pharma Park Cluster"
                        )}
                      </span>
                    </a>
                  </li>
                  <li>
                    <Icon
                      color={colors.bg2}
                      icon="ph:envelope-simple-duotone"
                      fontSize={24}
                      className="mr-3"
                    />{" "}
                    <a href="mailto:info@pharmatechuni.uz">
                      info@pharmatechuni.uz
                    </a>
                  </li>
                  <li>
                    <Icon
                      color={colors.bg2}
                      icon="ph:phone-duotone"
                      fontSize={24}
                      className="mr-3"
                    />
                    <a href="tel:+998958338181">+998 (95) 8338181</a>
                    <a className="ml-3" href="tel:+998999508080">
                      +998 (99) 9508080
                    </a>
                  </li>
                  <li>
                    <Icon
                      color={colors.bg2}
                      icon="ph:telegram-logo-duotone"
                      fontSize={24}
                      className="mr-3"
                    />{" "}
                    <a
                      href="https://t.me/ptuuz_bot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      t.me/ptuuz_bot
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl="7" lg="11">
              <div className="f-links">
                <Heading tag="h5" title={"Explore"} />
                <div className="d-flex flex-wrap">
                  <ul className="list-unstyled mr-5">
                    <NavigationLink pathName={"/"} title="Home" />
                    <NavigationLink pathName={"/study"} title="Study" />
                    <NavigationLink
                      pathName={"/apply-students"}
                      title="Apply"
                    />
                    <NavigationLink
                      pathName={"/academic-regulations"}
                      title="Academic Regulations"
                    />
                    <NavigationLink pathName={"/news"} title="News" />
                    <NavigationLink pathName={"/vacancies"} title="Vacancies" />
                    <NavigationLink pathName={"/team"} title="Our Team" />
                  </ul>
                  <ul className="list-unstyled">
                    <NavigationLink
                      pathName={"/partners"}
                      title="Our Partners"
                    />
                    <NavigationLink
                      pathName={"/strategy"}
                      title="Our Strategy"
                    />
                    <NavigationLink pathName={"/about"} title="About Us" />
                    <NavigationLink pathName={"/contact"} title="Contact Us" />
                    <NavigationLink pathName={"/faqs"} title="FAQs" />
                    <NavigationLink
                      pathName={"/anti-corruption"}
                      title="Anti-corruption"
                    />
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <BackToTop />
      </footer>
      <div className="copyright-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="copyright-wrapper">
                <p>
                  Pharmaceutical Technical University {new Date().getFullYear()}
                </p>
                <SocialMediaComponent />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Styles>
  );
};

export default Footer;
