import React from "react";

import { Styles } from "../styles/teamDetails.js";
import BreadcrumbBox from "../../../components/common/Breadcrumb.jsx";
import { Col, Container, Row } from "react-bootstrap";
import { useState } from "react";
import Heading from "../../../components/shared/Heading.jsx";

import teamData from "../../../data/team/team.json";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements.js";
import { TeamSectionAccordion } from "./TeamSectionAccordion";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export const TeamDetails = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState({});

  useEffect(() => {
    const selectedEmployee = teamData.dataList.find((data) => data.id === id);

    setEmployee(selectedEmployee);
  }, [id]);

  return (
    <Styles>
      <div className="main-wrapper team-details-page">
        <BreadcrumbBox
          title="Employee Profile"
          image="/assets/images/faq-2.jpg"
        />
        <section className="strategy-area">
          <Container>
            <Row>
              <Col md="12">
                <div className="main-details">
                  <figure className="image">
                    <img
                      title={employee?.name}
                      src={`/assets/images/${employee?.image}`}
                    />
                  </figure>
                  <div className="employee-details">
                    <h3 className="employee-name">{employee?.name}</h3>
                    <p className="employee-title">{employee?.title}</p>
                    <p className="employee-research">
                      Research Area: <strong>{employee?.research}</strong>
                    </p>
                    <div className="d-flex align-items-center mb-2">
                      <div>
                        <Icon
                          color={colors.bg2}
                          icon="ph:envelope-simple-duotone"
                          fontSize={30}
                          className="mr-2"
                        />
                      </div>
                      <a
                        href="mailto:adjumanov@pharmatechuni.uz"
                        className="employee-contact"
                        target="_blank"
                      >
                        {employee?.email}
                      </a>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <div>
                        <Icon
                          color={colors.bg2}
                          icon="ph:linkedin-logo-duotone"
                          fontSize={30}
                          className="mr-2"
                        />
                      </div>
                      <a
                        href={employee?.socialLinks?.linkedin}
                        className="employee-contact"
                        target="_blank"
                      >
                        {employee?.socialLinks?.linkedin}
                      </a>
                    </div>

                    {/* <div className="d-flex align-items-center mb-2">
                    <Icon
                      color={colors.bg2}
                      icon="mdi:phone-outline"
                      fontSize={30}
                      className="mr-2"
                    />
                    <a
                      href="tel:+998 99 000 00 00"
                      className="employee-contact"
                    >
                      {" "}
                      +998 99 000 00 00
                    </a>
                  </div> */}

                    {/* <li className="employee-social-media-link">
                        <a
                          href={employee?.socialLinks?.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Icon
                            icon="academicons:researchgate"
                            fontSize={20}
                            color="white"
                          />
                        </a>
                      </li> */}
                  </div>
                </div>
              </Col>

              <Col xs="12">
                {employee?.education?.length > 0 ? (
                  <TeamSectionAccordion
                    title="Education"
                    icon="ph:student-duotone"
                  >
                    {employee?.education?.map((education) => (
                      <div className="education">
                        <div className="education-duration">
                          {education.duration}
                        </div>
                        <div className="education-description">
                          {education.description}
                        </div>
                      </div>
                    ))}
                  </TeamSectionAccordion>
                ) : null}
                {employee?.experience?.length > 0 ? (
                  <TeamSectionAccordion
                    title="Employment History"
                    icon="ph:briefcase-duotone"
                  >
                    {employee?.experience?.map((education) => (
                      <div className="education">
                        <div className="education-duration">
                          {education.duration}
                        </div>
                        <div className="education-description">
                          {education.description}
                        </div>
                      </div>
                    ))}
                  </TeamSectionAccordion>
                ) : null}
                {employee?.publication?.length > 0 ? (
                  <TeamSectionAccordion
                    title="Publications"
                    icon="ph:files-duotone"
                  >
                    {employee?.publication?.map((publication) => (
                      <Row className="publication">
                        <Col
                          md={publication.link ? 11 : 12}
                          className="publication-text"
                        >
                          <p>{publication.text}</p>
                        </Col>
                        {publication.link ? (
                          <Col md="1" className="publication-link">
                            <a href={publication.link} target="_blank">
                              <Icon icon={"mdi:arrow-right"} fontSize={30} />
                            </a>
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                  </TeamSectionAccordion>
                ) : null}
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};
