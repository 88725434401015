import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

const RefereeInputsComponent = ({
  id,
  index,
  referee,
  state,
  setState,
  handleRefereeRemove,
  handleUpdateReferee,
  showDeleteButton,
}) => {
  const [organization, setOrganization] = useState(
    referee?.organization ? referee?.organization : ""
  );
  const [position, setPosition] = useState(
    referee?.position ? referee?.position : ""
  );
  const [name, setName] = useState(referee?.name ? referee?.name : "");
  const [phoneNumber, setPhoneNumber] = useState(
    referee?.phoneNumber ? referee?.phoneNumber : "+998"
  );
  const [email, setEmail] = useState(referee?.email ? referee?.email : "");

  useEffect(() => {
    const inputFields = {
      id,
      organization,
      position,
      name,
      phoneNumber,
      email,
    };

    handleUpdateReferee(id, inputFields);
    return;
  }, [name, organization, position, phoneNumber, email]);

  return (
    <>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Name and Surname"}
          type="text"
          placeholder={"Name and Surname"}
          name="name"
          id="name"
          value={name}
          onChange={(e) => setName(e)}
          required={index === 0}
        />
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Organization Name"}
          type="text"
          placeholder={"Organization Name"}
          name="organization"
          id="organization"
          value={organization}
          onChange={(e) => setOrganization(e)}
          required={index === 0}
        />
      </Col>
      <Col md="4">
        <FormWithInputAndLabel
          label={"Position in Organization"}
          type="text"
          placeholder={"Position"}
          name="position"
          id="position"
          value={position}
          onChange={(e) => setPosition(e)}
          required={index === 0}
        />
      </Col>
      <Col md="4">
        <FormWithInputAndLabel
          label={"Contact telephone numbers"}
          type="text"
          placeholder={"Contact telephone numbers"}
          name="phoneNumber"
          id="phoneNumber"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e)}
          required={index === 0}
        />
      </Col>
      <Col md="4">
        <FormWithInputAndLabel
          label={"Email"}
          type="email"
          placeholder={"Email"}
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e)}
          required={index === 0}
        />
      </Col>
      {showDeleteButton ? (
        <Col md="12" className="d-flex justify-content-end">
          <Button
            text={"Remove"}
            iconName={solid("trash")}
            onClick={() => handleRefereeRemove(id, state, setState)}
          />
        </Col>
      ) : (
        <Col md="12" className="mb-4" />
      )}
    </>
  );
};

export default RefereeInputsComponent;
