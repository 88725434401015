import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

import { DURATION_ENUM } from "../../utilities/constants";

const EducationInputsComponent = ({
  id,
  education,
  state,
  setState,
  handleEducationRemove,
  handleUpdateEducation,
  showDeleteButton,
  isEducationSection,
}) => {
  const [startDate, setStartDate] = useState(
    education?.startDate ? education.startDate : ""
  );
  const [endDate, setEndDate] = useState(
    education?.endDate ? education.endDate : ""
  );
  const [institution, setInstitution] = useState(
    education?.institution ? education?.institution : ""
  );
  const [qualification, setQualification] = useState(
    education?.qualification ? education?.qualification : ""
  );
  const [isPresent, setIsPresent] = useState(false);

  useEffect(() => {
    const inputFields = {
      id,
      startDate,
      endDate,
      institution,
      qualification,
      duration: isPresent ? DURATION_ENUM.TILL_NOW : DURATION_ENUM.FINISHED,
    };

    handleUpdateEducation(id, inputFields);
    return;
  }, [startDate, endDate, institution, qualification, isPresent]);

  return (
    <>
      <Col md="5">
        <FormWithInputAndLabel
          label={"Start Date"}
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e)}
          required={isEducationSection}
        />
      </Col>
      <Col md="7">
        <Row>
          {!isPresent && (
            <Col lg="8">
              <FormWithInputAndLabel
                label={"End Date"}
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e)}
                required={isEducationSection}
              />
            </Col>
          )}
          <Col lg="4">
            {isPresent && (
              <div className="form-control mb-2">
                <label>End Date</label>
              </div>
            )}
            <div className={`${isPresent ? "" : "mt-5"} custom-checkbox`}>
              <input
                id="1"
                type="checkbox"
                onClick={() => setIsPresent((prev) => !prev)}
              />
              <label htmlFor={"1"}>Present</label>
            </div>
          </Col>
        </Row>
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Name of Institution, place and country"}
          type="text"
          placeholder={"Name of Institution"}
          name="institution"
          value={institution}
          onChange={(value) => setInstitution(value)}
          required={isEducationSection}
        />
      </Col>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Qualification"}
          type="text"
          placeholder={"Qualification"}
          name="qualification"
          id="qualification"
          value={qualification}
          onChange={(e) => setQualification(e)}
          required={isEducationSection}
        />
      </Col>
      {showDeleteButton && (
        <Col md="12" className="d-flex justify-content-end">
          <Button
            text={"Remove"}
            iconName={solid("trash")}
            onClick={() => handleEducationRemove(id, state, setState)}
          />
        </Col>
      )}
    </>
  );
};

export default EducationInputsComponent;
