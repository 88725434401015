import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .announcement-header {
    margin-top: -1px;
    background: rgba(0, 0, 0, 0.7);
    box-shadow: 0 8px 32px rgb(31 31 31 / 37%);
    backdrop-filter: blur(4px);

    .announcement-text {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.text6};
      text-align: center;

      p {
        font-weight: 600;

        @media (max-width: 500px) {
          margin-bottom: 10px;
        }
      }

      a {
        margin-left: 15px;
        padding: 5px 20px;
        background-color: ${colors.bg2};
        border-radius: 8px;
        font-weight: 700;
        color: ${colors.black1};

        @media (max-width: 500px) {
          margin-bottom: 10px;
        }
      }
      a:hover {
        background-color: ${colors.text6};
      }

      @media (max-width: 500px) {
        display: block;
      }
    }
  }
`;
