import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import BreadcrumbBox from "../../components/common/Breadcrumb.jsx";
import VacancyItem from "../../components/career/VacancyItem.jsx";

import { Styles } from "./styles/apply.js";
import { useTranslation } from "react-i18next";
import EnglishData from "../../data/course/filter.json";
import RussianData from "../../data/course/filter-rus.json";
import UzbekData from "../../data/course/filter-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector.js";

const ApplyPractitioners = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [courseData] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  const cpdCourse = courseData?.dataList?.find((data) => data.type === "cpd");

  return (
    <Styles>
      <div className="main-wrapper registration-page">
        <BreadcrumbBox title="Apply" image="/assets/images/pre-uni.jpg" />
        <section className="registration-area">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col
                md="4"
                className="mb-4 d-flex justify-content-center align-items-center apply-navigation p-0"
              >
                <div
                  className="right px-5 py-3 text-center"
                  onClick={() => navigate("/apply-students")}
                >
                  {t("For Students")}
                </div>
                <div className="apply-navigation-active px-5 py-3 text-center left">
                  {t("For Practitioners")}
                </div>
              </Col>
              <Col md="12" className="mt-4">
                <Row>
                  {cpdCourse?.structure.map((course) => (
                    <Col lg="12" md="12" key={course?.title}>
                      <VacancyItem
                        title={course.title}
                        buttonsToRender={[
                          {
                            title: "View",
                            onClick: () => navigate("/programme-details/10"),
                          },
                          {
                            title: "Apply Now",
                            onClick: () =>
                              window.open(
                                cpdCourse.applyLink,
                                "_blank",
                                "rel=noopener noreferrer"
                              ),
                          },
                        ]}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default ApplyPractitioners;
