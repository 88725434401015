import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import EnglishData from "../../data/accommodation/accommodation.json";
import RussianData from "../../data/accommodation/accommodation-ru.json";
import UzbekData from "../../data/accommodation/accommodation-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";

const Accommodation = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper">
      <BreadcrumbBox
        title="Accommodation"
        image="/assets/images/accommodation.jpg"
      />
      <section className="wellbeing-area">
        <Container>
          <Row>
            <Col xs="12">
              <div className="wellbeing-content">
                <div className="py-5">
                  <p className="pb-3">{data?.subtitle1}</p>
                  <p className="pb-3">{data?.subtitle2}</p>
                  <p className="pb-3">
                    <u>{data?.subtitle3?.underlined}:</u>
                    <span> {data?.subtitle3?.normal}</span>
                  </p>
                  <p className="pb-3">
                    <span>{data?.subtitle4?.prelink1}</span>
                    <a href={`mailto:${data?.subtitle4?.link1}`}>
                      {data?.subtitle4?.link1}
                    </a>
                    <span>{data?.subtitle4?.prelink2}</span>
                    <a href={`mailto:${data?.subtitle4?.link2}`}>
                      {data?.subtitle4?.link2}
                    </a>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Accommodation;
