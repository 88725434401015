export const appendToFormData = (formData, valuesObject) => {
  for (const property in valuesObject) {
    formData.append(property, valuesObject[property]);
  }
};

export function appendObjectToFormData(formData, data, parentKey = '') {
  for (const [key, value] of Object.entries(data)) {
    const formKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      if (Object.keys(value).length === 0) {
        // For empty objects, append an empty string or skip
        formData.append(formKey, '');
      } else {
        // For non-empty objects, stringify
        formData.append(formKey, JSON.stringify(value));
      }
    } else {
      formData.append(formKey, value);
    }
  }
}