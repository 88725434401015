import React, { useEffect } from "react";
import MobileMenu from "../common/MobileMenu";
import Preheader from "../../layout/preheader/Preheader";
import WebMenu from "./WebMenu/index.jsx";
import { Styles } from "./style.js";

const Header = ({ showAfter }) => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const stickyMenu = document.querySelector(".logo-area2");

      if (showAfter) {
        if (window.scrollY > 0) stickyMenu.classList.remove("d-none");
        if (window.scrollY > 160) {
          stickyMenu.classList.add("sticky");
          stickyMenu.classList.remove("d-none");
        } else {
          stickyMenu.classList.add("d-none");
          stickyMenu.classList.remove("sticky");
        }
      }
      if (!showAfter) {
        stickyMenu.classList.remove("d-none");
        if (window.scrollY > 5) {
          stickyMenu.classList.add("sticky");
        } else {
          stickyMenu.classList.remove("sticky");
        }
      }
    });
  }, [showAfter]);

  return (
    <Styles>
      <Preheader showAfter={showAfter} />
      <WebMenu />
      {!showAfter && <MobileMenu />}
    </Styles>
  );
};

export default Header;
