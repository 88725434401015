import React from "react";

import BreadcrumbBox from "../../components/common/Breadcrumb";
import LatestNewsComponent from "../components/smartComponents/LatestNewsComponent";
import NewsListComponent from "../components/smartComponents/NewsListComponent";

import { Styles } from "../styles/news.js";

const NewsPage = () => {
  return (
    <Styles>
      <div className="main-wrapper news-page">
        <BreadcrumbBox title="News" image="/assets/images/faq.jpg" />
        <section className="news-area">
          <LatestNewsComponent />
          <NewsListComponent />
        </section>
      </div>
    </Styles>
  );
};

export default NewsPage;
