import styled from "styled-components";
import { colors, border } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .careers-page {
    .headers {
      display: flex;
      justify-content: space-between;
      div {
        display: flex;
        justify-content: space-between;
        margin: 10px 30px;
        h3 {
          font-weight: 600;
        }
        h4 {
          font-weight: 600;
          margin: 0 35px;
        }
      }
    }
    .team-block {
      margin-bottom: 30px;

      .inner-box {
        position: relative;
        margin-bottom: 30px;

        .image-box {
          position: relative;
          height: 275px;
          width: 275px;
          border-radius: 50%;
          padding: 27px;
          margin: 0 auto 20px;

          :after {
            -webkit-clip-path: polygon(
              50% 0,
              50% 0,
              50% 50%,
              50% 100%,
              50% 100%,
              50% 50%
            );
            clip-path: polygon(
              50% 0,
              50% 0,
              50% 50%,
              50% 100%,
              50% 100%,
              50% 50%
            );
          }

          :after,
          :before {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 50%;
            border: 8px solid #f2f8f8;
            border-top: 0;
            content: "";
            border-radius: 0 0 200px 200px;
            transition: all 0.7s ease;
          }

          .image {
            position: relative;
            height: 220px;
            width: 220px;
            border-radius: 50%;
            overflow: hidden;
            margin-bottom: 0;
            z-index: 1;

            img {
              width: 100%;
              transition: all 0.4s ease;
            }
          }

          .share-icon {
            left: 30px;
            top: 20px;
            height: 50px;
            width: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 16px;
            color: ${colors.bg2};
            border-radius: 50%;
            transition: all 0.3s ease;
          }

          .share-icon,
          .social-links {
            position: absolute;
            background-color: #fff;
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
            z-index: 3;
          }

          .social-links {
            left: 35px;
            top: 28px;
            display: flex;
            align-items: center;
            flex-direction: column;
            transform: scaleY(0);
            transform-origin: top;
            visibility: hidden;
            opacity: 0;
            transition: all 0.4s ease;
            border-radius: 20px;
            overflow: hidden;
            padding: 10px 0;

            a {
              position: relative;
              height: 35px;
              width: 40px;
              display: block;
              font-size: 14px;
              line-height: 35px;
              text-align: center;
              color: ${colors.black1};
              transition: all 0.3s ease;

              :hover {
                color: ${colors.bg1};
              }
            }
          }
        }

        .info-box {
          text-align: center;
          h6 {
            font-weight: 600;
            color: ${colors.black1};
          }
          p {
            color: ${colors.black1};
          }
        }

        :hover {
          .image img {
            transform: scale(1.1);
            transform-origin: top center;
          }

          .image-box:after {
            border-color: ${colors.bg1};
            -webkit-clip-path: polygon(
              25% -70%,
              75% -70%,
              120% 50%,
              75% 170%,
              25% 170%,
              -20% 50%
            );
            clip-path: polygon(
              25% -70%,
              75% -70%,
              120% 50%,
              75% 170%,
              25% 170%,
              -20% 50%
            );
          }

          .social-links {
            transform: scaleY(1);
            opacity: 1;
            visibility: visible;
          }

          .share-icon {
            background-color: ${colors.bg2};
          }
        }
      }
    }
  }
`;
