import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Styles } from "./styles/conference.js";
import IconBox from "../../components/IconBox.jsx";
import Heading from "../../components/shared/Heading";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "../../data/conference/conference-en.json";
import RussianData from "../../data/conference/conference-rus.json";
import UzbekData from "../../data/conference/conference-uz.json";
import IconBoxData from "./data/iconbox.json";
import IconBoxDataRu from "./data/iconbox-ru.json";
import IconBoxDataUz from "./data/iconbox-uz.json";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements.js";

const AboutConference = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const [iconboxData] = useLanguageDetector(
    IconBoxData,
    IconBoxDataRu,
    IconBoxDataUz
  );
  return (
    <Styles>
      <div className="main-wrapper conference-page">
        <section
          className="breadcrumb"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/event-6.jpg)`,
          }}
        >
          <Container>
            <Row>
              <Col md="12">
                <div className="breadcrumb-box">
                  <h2 className="breadcrumb-title">
                    PTU Inaugural International Conference on Pharmaceutics and
                    Life Sciences
                  </h2>
                  <h2 className="year">ICPL 2024</h2>
                  <p className="device">
                    Frontiers of research and higher education
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <IconBox data={iconboxData} />

        <section id="about" className="about">
          <Container>
            <Row>
              <Col md="12">
                <div className="main">
                  <div className="title-wrapper">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data.title}
                    />
                  </div>
                  {data.descriptions.map((desc, i) => (
                    <p className="description" key={i}>
                      {desc}
                    </p>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="thematic">
          <Container>
            <Row>
              <Col md="12">
                <div className="main">
                  <Heading
                    tag="h4"
                    className="strategy-title"
                    title={"Thematic Areas"}
                  />
                  <div className="topic-list">
                    {data.thematic_areas.map((area, i) => (
                      <div className="topic" key={i}>
                        {area}
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="committee">
          <Container>
            <Row>
              <Col md="12">
                <div className="main">
                  <Heading
                    tag="h4"
                    className="strategy-title"
                    title={"Conference Chairs"}
                  />
                  <div className="committee-list">
                    {data.conference_chairs.map((member, i) => (
                      <div className="committee" key={i}>
                        <div className="name">{member.name}</div>
                        <div className="job">{member.job}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="committee">
          <Container>
            <Row>
              <Col md="12">
                <div className="main">
                  <Heading
                    tag="h4"
                    className="strategy-title"
                    title={"Organising Committee"}
                  />
                  <div className="committee-list">
                    {data.committe_members.map((member, i) => (
                      <div className="committee" key={i}>
                        <div className="name">{member.name}</div>
                        <div className="job">{member.job}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="committee">
          <Container>
            <Row>
              <Col md="12">
                <div className="main">
                  <Heading
                    tag="h4"
                    className="strategy-title"
                    title={"Conference Secretariat"}
                  />
                  <div className="committee-list">
                    {data.conference_secretariat.map((member, i) => (
                      <div className="committee" key={i}>
                        <div className="name">{member.name}</div>
                        <div className="job">{member.job}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default AboutConference;
