import React from "react";
import TabbarComponent from "../../../../components/shared/TabbarComponent";
import { Tab } from "react-bootstrap";
import CouresDetailsOverviewTab from "../CourseDetailsOverviewTab";
import CourseDetailsStructureTab from "../CourseDetailsStructureTab";
import CourseDetailsRequirementsTab from "../CourseDetailsRequirementsTab";
import CourseDetailsSampleExamTab from "../CourseDetailSampleExamTab";

export const UniversityProgrammeContent = ({ courseData }) => {
  return (
    <div className="course-tab-list">
      <TabbarComponent
        tabTitles={["Overview", "Structure", "Requirements", "Exam Materials"]}
        tabContent={
          <Tab.Content>
            <CouresDetailsOverviewTab
              data={courseData?.overview}
              courseEnum={courseData?.courseEnum}
            />
            <CourseDetailsStructureTab
              data={courseData?.structure}
              postTitle={courseData?.courseStructurePostTitle}
            />
            <CourseDetailsRequirementsTab
              key={courseData?.admissionRequirements}
              data={courseData?.admissionRequirements}
            />
            <CourseDetailsSampleExamTab data={courseData?.sampleExam} />
          </Tab.Content>
        }
        defaultActiveKey="Overview"
      />
    </div>
  );
};
