import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

import { languageMenu } from "../../configs/i18nConfig";
import EventEmitter, { EVENT_TOPICS } from "../../utilities/eventUtils";

const LanguageDropdownComponent = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(languageMenu[1]);

  const handleOnLanguageMenuItemSelected = (item) => {
    if (language?.id !== item?.id) {
      setLanguage(item);
      i18n.changeLanguage(item.code);

      EventEmitter.emit(EVENT_TOPICS.ON_LANGUAGE_CHANGED, {
        language: item?.code,
      });
    }
  };

  useEffect(() => {
    const detectedLanguageMenu = languageMenu.find((l) =>
      i18n.language.includes(l.code)
    );

    setLanguage(detectedLanguageMenu);
  }, [i18n.language]);

  const options = languageMenu?.filter((item) => language?.id !== item.id);

  return (
    <ul className="list-unstyled list-inline bar-lang">
      <li className="list-inline-item">
        <Dropdown>
          <Dropdown.Toggle className="d-flex align-items-center">
            <img src={language?.icon} alt={language?.title} />
            {language?.title}
            <i className="las la-angle-down"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu as="ul">
            {options.map((option) => (
              <Dropdown.Item
                as="li"
                onClick={() => handleOnLanguageMenuItemSelected(option)}
                key={option?.id}
              >
                <img src={option.icon} alt={option.title} /> {option.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </ul>
  );
};

export default LanguageDropdownComponent;
