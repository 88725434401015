import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Styles } from "./styles/breadcrumb.js";

export const BreadcrumbBox = ({
  title,
  previousPath = "/",
  previousTitle = "Home",
  image = "/assets/images/test.jpg",
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Styles>
      <section
        className="breadcrumb-area"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}${image})`,
        }}
      >
        <Container>
          <Row>
            <Col md="12">
              <div className="breadcrumb-box">
                <h2 className="breadcrumb-title">{t(title)}</h2>
                <Breadcrumb>
                  <Breadcrumb.Item onClick={() => navigate(previousPath)}>
                    {t(previousTitle)}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>{t(title)}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
};

export default BreadcrumbBox;
