import React, { useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Heading from "../../../components/shared/Heading";
import Fetcher from "../../../components/shared/Fetcher";
import LatestNewsBlockComponent from "../reusableComponents/LatestNewsBlockComponent";
import LatestNewsSkeleton from "../reusableComponents/skeletons/LatestNewsSkeleton";

import newsService from "../../service/newsService";
import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import { useQueryParamByName } from "../../../hooks/useQueryParamByName";
import { IMAGE_KEYS } from "../../../utilities/constants";
import { useCurrentLanguage } from "../../../hooks/useCurrentLanguage";

import { Styles } from "../../styles/latestNewsPage.js";
import useFetchCategories from "../../hooks/useFetchCategories";
import Button from "../../../components/shared/Button";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const LatestNewsComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { newsState, newsDispatch } = useNewsPageContext();
  const currentPageQueryString = useQueryParamByName("current-page");
  const pageSizeQueryString = useQueryParamByName("page-size");
  const [language] = useCurrentLanguage();
  const [fetchCategories, handleOnLoadFetchedCategories] = useFetchCategories();

  const fetchLatestNews = useCallback(
    async () =>
      await newsService.getNews({
        isLatestNews: true,
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedLatestNews = useCallback(
    (response) => {
      newsDispatch({ type: "latestNewsFetch", response: response });
    },
    [newsDispatch]
  );

  return (
    <Styles>
      <Container>
        <Row>
          <Col md="12" className={location?.pathname !== "/" ? "mb-5" : ""}>
            <Fetcher
              action={fetchCategories}
              onLoad={handleOnLoadFetchedCategories}
              loadingComponent={<LatestNewsSkeleton />}
            >
              <Fetcher
                action={fetchLatestNews}
                onLoad={handleOnLoadFetchedLatestNews}
                loadingComponent={<LatestNewsSkeleton />}
              >
                <Heading
                  tag="h4"
                  title={"Latest News"}
                  className="news-title"
                />
                <div className="latest-news-container">
                  <LatestNewsBlockComponent
                    className={"latest-news-taller"}
                    image={
                      newsState?.latestNews?.[0]?.latestNews?.url ??
                      newsState?.latestNews?.[0]?.imageUrls?.[language]?.[
                        IMAGE_KEYS.COVER_IMAGE_GET
                      ]
                    }
                    categories={newsState?.latestNews?.[0]?.categoryIds}
                    title={newsState?.latestNews?.[0]?.title?.[language]}
                    onClick={() =>
                      navigate(`/news/${newsState?.latestNews?.[0]?.id}`)
                    }
                  />
                  <div className="latest-news-wider">
                    <LatestNewsBlockComponent
                      className={"latest-news-wider-rectangle"}
                      image={
                        newsState?.latestNews?.[1]?.latestNews?.url ??
                        newsState?.latestNews?.[1]?.imageUrls?.[language]?.[
                          IMAGE_KEYS.COVER_IMAGE_GET
                        ]
                      }
                      categories={newsState?.latestNews?.[1]?.categoryIds}
                      title={newsState?.latestNews?.[1]?.title?.[language]}
                      onClick={() =>
                        navigate(`/news/${newsState?.latestNews?.[1]?.id}`)
                      }
                    />
                    <div className="latest-news-wider-squares-wrapper">
                      <LatestNewsBlockComponent
                        className={"latest-news-wider-square"}
                        image={
                          newsState?.latestNews?.[2]?.latestNews?.url ??
                          newsState?.latestNews?.[2]?.imageUrls?.[language]?.[
                            IMAGE_KEYS.COVER_IMAGE_GET
                          ]
                        }
                        categories={newsState?.latestNews?.[2]?.categoryIds}
                        title={newsState?.latestNews?.[2]?.title?.[language]}
                        onClick={() =>
                          navigate(`/news/${newsState?.latestNews?.[2]?.id}`)
                        }
                      />
                      <LatestNewsBlockComponent
                        className={"latest-news-wider-square"}
                        image={
                          newsState?.latestNews?.[3]?.latestNews?.url ??
                          newsState?.latestNews?.[3]?.imageUrls?.[language]?.[
                            IMAGE_KEYS.COVER_IMAGE_GET
                          ]
                        }
                        categories={newsState?.latestNews?.[3]?.categoryIds}
                        title={newsState?.latestNews?.[3]?.title?.[language]}
                        onClick={() =>
                          navigate(`/news/${newsState?.latestNews?.[3]?.id}`)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Fetcher>
            </Fetcher>
          </Col>
          {location?.pathname === "/" ? (
            <Col md="12" className="d-flex justify-content-end">
              <Button
                className={"view-more-button-wrapper"}
                text="View more news"
                outline
                iconName={"ph:newspaper-duotone"}
                onClick={() => navigate("/news")}
              />
            </Col>
          ) : null}
        </Row>
      </Container>
    </Styles>
  );
};

export default LatestNewsComponent;
