import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Heading from "../../components/shared/Heading";

import { useLanguageDetector } from "../../hooks/useLanguageDetector";

import "./Strategy.css";

import EnglishData from "../../data/strategy/strategy.json";
import RussianData from "../../data/strategy/strategy-rus.json";
import UzbekData from "../../data/strategy/strategy-uz.json";

const Strategy = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper strategy-page">
      <BreadcrumbBox title="Our Strategy" image="/assets/images/banner-8.jpg" />
      <section className="strategy-area">
        <Container>
          <Row>
            <Col md="12">
              <div>
                <p className="strategy-desc">{data?.description}</p>
                <div className="strategy-image-wrapper">
                  <img
                    className="strategy-image"
                    src={`/assets/images/${data?.image}`}
                    alt="startegy"
                  />
                </div>
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={"Strategic Plan"}
                />
                {data?.strategicPlan?.map((plan) => (
                  <div key={plan.id}>
                    <h6 className="strategy-years">{plan.year}</h6>
                    <p className="strategy-desc">{plan.description}</p>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Strategy;
