import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import FormWithInputAndLabel from "../shared/FormWithInputAndLabel";
import Button from "../shared/Button";

import { LANGUAGE_LEVELS } from "../../utilities/constants";

const LanguageInputsComponent = ({
  id,
  language,
  state,
  setState,
  handleLanguageRemove,
  handleUpdateLanguage,
  showDeleteButton,
}) => {
  const [languageName, setLanguageName] = useState(
    language?.languageName ? language?.languageName : ""
  );
  const [level, setLevel] = useState(
    language?.level ? language?.level : LANGUAGE_LEVELS.ELEMENTARY
  );

  useEffect(() => {
    const inputFields = {
      id,
      languageName,
      level,
    };

    handleUpdateLanguage(id, inputFields);
    return;
  }, [languageName, level]);

  return (
    <>
      <Col md="6">
        <FormWithInputAndLabel
          label={"Language"}
          type="text"
          placeholder={"Language Name"}
          name="language"
          value={languageName}
          onChange={(value) => setLanguageName(value)}
          required
        />
      </Col>
      <Col md="6">
        <div className="form-control">
          <label htmlFor={"select"}>
            {"Level"}
            <strong className="text-danger">*</strong>
          </label>
          <select
            id={"select"}
            value={level}
            onChange={(e) => setLevel(e.target.value)}
          >
            {Object.values(LANGUAGE_LEVELS).map((language) => (
              <option value={language} key={language}>
                {language}
              </option>
            ))}
          </select>
        </div>
      </Col>
      {showDeleteButton && (
        <Col md="12" className="d-flex justify-content-end">
          <Button
            text={"Remove"}
            iconName={solid("trash")}
            onClick={() => handleLanguageRemove(id, state, setState)}
          />
        </Col>
      )}
    </>
  );
};

export default LanguageInputsComponent;
