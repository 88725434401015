import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";

import Heading from "../../components/shared/Heading";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import { Col, Container, Row } from "react-bootstrap";

import { Styles } from "./style.js";
import EnglishData from "../../data/cpd/cpd.json";
import RussianData from "../../data/cpd/cpd-rus.json";
import UzbekData from "../../data/cpd/cpd-uz.json";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "'Montserrat', sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child th": {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: "#662a30",
    color: "white",
    fontFamily: "'Montserrat', sans-serif",
  },
}));

const CPD = () => {
  const [searchParams] = useSearchParams();
  const [cpdData] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const activeTab = searchParams.get("course-title");
  const [course, setCourse] = useState(
    cpdData?.find((data) => data.courseKey.toLowerCase() === activeTab)
  );

  useEffect(
    () =>
      setCourse(
        cpdData?.find((data) => data.courseKey.toLowerCase() === activeTab)
      ),
    [cpdData]
  );

  return (
    <Styles>
      <div className="main-wrapper cpd-page">
        <BreadcrumbBox
          title="Continuous Personal Development"
          image="/assets/images/banner-2.jpg"
        />
        <section className="course-list-area">
          <Container>
            <Row>
              <Col lg="12" md="12" sm="12">
                <div className="main-content">
                  <Heading
                    tag="h5"
                    title={course?.heading}
                    className={"strategy-title"}
                  />

                  <div className="course-desc">
                    <TableContainer
                      component={Card}
                      sx={{
                        boxShadow: "0 1px 10px 5px rgb(0 0 0 / 3%);",
                      }}
                    >
                      <Table sx={{ minWidth: 320 }} aria-label="simple table">
                        <TableHead>
                          <StyledTableRow>
                            {cpdData?.[0].tableHeaders.map((title) => (
                              <TableCell key={title}>{title}</TableCell>
                            ))}
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {course?.students?.map((row) => (
                            <TableRow
                              hover
                              key={row.certificate}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell component="th" scope="row">
                                {row.certificate}
                              </StyledTableCell>
                              <StyledTableCell>{row.name}</StyledTableCell>
                              <StyledTableCell>{row.duration}</StyledTableCell>
                              <StyledTableCell>
                                {row.diploma ? row.diploma : "-"}
                              </StyledTableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default CPD;
