import styled from "styled-components";
import { colors } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .faqs-area {
    padding: 40px 0;

    @media (max-width: 767px) {
      padding: 30px;
    }
  }

  .accordion {
    .faq-description {
      p {
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: 500;

        a {
          font-size: 16px;
          font-weight: 500;
          color: ${colors.bg1};
          padding-left: 5px;
          text-decoration: underline;
        }
      }
    }

    &-heading {
      padding-bottom: 1.5rem;
    }

    &-item {
      margin-bottom: 20px;

      &:not(:last-child) {
        .accordion-head {
        }
      }
      &:last-child {
        .accordion-inner {
          border-top: 1px solid black;
        }
      }
    }
    &-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border-radius: 8px;
      box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
      cursor: pointer;
      padding: 20px 25px;
      background-color: ${colors.bg1};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      .title {
        margin-bottom: 0;
        font-size: 16px;
        font-weight: 600;
        color: ${colors.text6};
      }

      .accordion-icon {
        margin-left: 5px;

        i {
          color: white;
        }
      }

      &.collapsed {
        background-color: white;
        border-radius: 8px;
        .title {
          color: ${colors.bg1};
        }

        .accordion-icon {
          i {
            color: ${colors.bg1};
          }
        }
      }
    }

    &-inner {
      background-color: white;
      padding: 30px 25px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: 0 5px 25px rgb(0 0 0 / 10%);

      div {
        p {
          a {
            color: ${colors.bg1};
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
    }

    &-icon {
      font-size: 1rem;
      color: ${colors.bg1};
      transition: rotate 0.4s;
      text-align: center;

      i {
        color: ${colors.bg1};
      }
    }
  }
  @media (min-width: 768px) {
    .accordion {
      &-head {
        padding: 1rem 1.5rem;
      }
      &-inner {
        padding: 1rem 1.25rem (1rem * 1.25);
      }
      &-icon {
        @if ($rtl==false) {
          right: 1.5rem;
        }
        @if ($rtl==true) {
          left: 1.5rem;
        }
      }
    }
  }
`;
