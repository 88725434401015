import styled from "styled-components";
import { colors } from "../../common/element/elements.js";

export const Styles = styled.div`
  ul.social {
    li {
      a {
        text-align: center;
        position: relative;
        display: inline-block;

        i {
          font-size: 24px;
          color: ${colors.text6};
          width: 34px;

          :hover {
            color: ${colors.bg2};
          }
        }
      }
    }
  }
`;
