import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles/social";
import EnglishData from "../../data/social/social.json";
import RussianData from "../../data/social/social-ru.json";
import UzbekData from "../../data/social/social-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";

const Social = () => {
  const [socialData] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper social-page">
      <BreadcrumbBox
        title="Social Engagement - Clubs"
        image="/assets/images/social-2.jpg"
      />
      <Styles>
        <section className="social-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="social-content">
                  {socialData?.paragraphs?.map((par, index) => (
                    <p key={index} className="par">
                      {par}
                    </p>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default Social;
