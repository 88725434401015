import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const FormWithInputAndLabel = ({
  label,
  type,
  placeholder,
  name,
  register = () => {},
  defaultValue,
  value,
  onChange,
  id,
  errors,
  validation,
  isTextArea,
  required,
  error,
  errorMessage,
  control,
  ...props
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(value);

  const handleOnInputChange = (e) => {
    if (onChange) onChange(e.target.value);

    setInputValue(e.target.value);
  };

  return (
    <div className="form-control">
      <label htmlFor={id}>
        {t(label)}
        {required ? <strong className="text-danger">*</strong> : null}
      </label>
      {!isTextArea ? (
        <input
          type={type}
          placeholder={t(placeholder)}
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={inputValue}
          lang="ru"
          onChange={handleOnInputChange}
          {...register(name, {
            required: "Input field is required to fill*",
          })}
          {...validation}
          {...props}
        />
      ) : (
        <textarea
          placeholder={placeholder}
          name={name}
          id={id}
          defaultValue={defaultValue}
          value={inputValue}
          onChange={handleOnInputChange}
          {...register(name, {
            required: "Input field is required to fill*",
          })}
          {...validation}
          {...props}
        />
      )}
      {(errors?.[name] || error) && (
        <p className="invalid">{errors?.[name]?.message || error?.message}</p>
      )}
    </div>
  );
};

export default FormWithInputAndLabel;
