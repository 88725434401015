import React from "react";

import SpinnerComponent from "./SpinnerComponent";
import { useAutomaticFetcher } from "../../hooks/useAutomaticFetcher";
import { FETCH_STATE } from "../../utilities/constants";

const Fetcher = ({
  action,
  children,
  onLoad,
  refreshInterval,
  loadingComponent,
}) => {
  const [fetchState, error, loadData] = useAutomaticFetcher({
    action,
    onLoad,
    refreshInterval,
  });

  if (fetchState === FETCH_STATE.LOADING) {
    return loadingComponent ?? <SpinnerComponent />;
  }

  if (fetchState === FETCH_STATE.ERROR) {
    return (
      <div> ERROR PAGE: [{error?.message ?? "Something went wrong"}] </div>
    );
  }

  if (fetchState === FETCH_STATE.COMPLETE) {
    if (typeof children == "function") {
      return <>{children(loadData)}</>;
    }

    return children;
  }

  return null;
};

export default Fetcher;
