import React from "react";
import { Col } from "react-bootstrap";
import { Collapse } from "reactstrap";
import { useEventListener } from "../../../hooks/useEventListener";

import RichTextHTMLParser from "../../../newsPage/components/reusableComponents/RichTextHTMLParser";
import { EVENT_TOPICS } from "../../../utilities/eventUtils";

const AccordionItem = ({ id, title, textContent, isOpen, onClick }) => {
  return (
    <Col md="6" xl="6" className="accordion-item">
      <div
        className={[`accordion-head${isOpen !== id ? " collapsed" : ""}`]}
        onClick={onClick}
      >
        <h6 className="title">{title}</h6>
        <div className="accordion-icon">
          {isOpen !== id ? (
            <i className="fas fa-chevron-down"></i>
          ) : (
            <i className="fas fa-chevron-up"></i>
          )}
        </div>
      </div>
      <Collapse
        className="accordion-body"
        isOpen={isOpen === id ? true : false}
      >
        <div className="accordion-inner">
          <RichTextHTMLParser text={textContent} />
        </div>
      </Collapse>
    </Col>
  );
};

export default AccordionItem;
