import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Heading from "../../../../components/shared/Heading";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import { colors } from "../../../../components/common/element/elements";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "'Montserrat', sans-serif",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child th": {
    fontSize: 16,
    fontWeight: 600,
    backgroundColor: "#662a30",
    color: "white",
    fontFamily: "'Montserrat', sans-serif",
  },
}));

export const PreFoundationProgrammeContent = ({ courseData }) => {
  return (
    <div className="course-tab-list">
      <div className="instructor-tab">
        {Array.isArray(courseData.overview.courseDescription) ? (
          courseData.overview.courseDescription.map((desc) => (
            <div className="instructor-description">{desc}</div>
          ))
        ) : (
          <div className="instructor-description">
            {courseData.overview.courseDescription}
          </div>
        )}
        <div className="course-learn">
          <p>{courseData?.overview.learningOutcomes?.description}</p>
          <ul className="list-unstyled learning-outcomes-list">
            {courseData?.overview.learningOutcomes?.arguments?.map(
              (argument) => (
                <motion.li key={argument} whileHover={{ scale: 1.02 }}>
                  <div className="course-details-icon-wrapper">
                    <Icon
                      icon="ph:check-circle-duotone"
                      fontSize={30}
                      color={colors.bg2}
                    />
                  </div>
                  <p className="pl-1">{argument}</p>
                </motion.li>
              )
            )}
          </ul>
        </div>

        <div className="course-learn">
          <p>{courseData?.overview.courseCreatedFor?.description}</p>
          <ul className="list-unstyled learning-outcomes-list">
            {courseData?.overview.courseCreatedFor?.arguments?.map(
              (argument) => (
                <motion.li key={argument} whileHover={{ scale: 1.02 }}>
                  <div className="course-details-icon-wrapper">
                    <Icon
                      icon="ph:check-circle-duotone"
                      fontSize={30}
                      color={colors.bg2}
                    />
                  </div>
                  <p className="pl-1">{argument}</p>
                </motion.li>
              )
            )}
          </ul>
        </div>

        <div className="instructor-description">
          {courseData.overview.courseLink.text}
          <Link
            to={courseData.overview.courseLink.link.link}
            style={{
              color: colors.bg1,
              textDecoration: "underline",
            }}
          >
            {courseData.overview.courseLink.link.text}
          </Link>
        </div>

        <Heading tag="h5" title={"Programme Details"} />
        {courseData.overview.courseDetails?.map((info) => (
          <div>
            <TableContainer
              component={Card}
              sx={{
                boxShadow: "0 1px 10px 5px rgb(0 0 0 / 3%);",
                mb: 4,
              }}
            >
              <Table sx={{ minWidth: 320 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    {info.titles?.map((title) => (
                      <TableCell key={title}>{title}</TableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {info.semester.map((row) => (
                    <TableRow
                      hover
                      key={row.title}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.title}
                      </StyledTableCell>
                      <StyledTableCell>{row.duration}</StyledTableCell>
                      <StyledTableCell>{row.language}</StyledTableCell>
                      <StyledTableCell>{row.type}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ))}

        <div className="my-3">
          <Heading tag="h5" title={"Price List"} />
          <TableContainer
            component={Card}
            sx={{
              boxShadow: "0 1px 10px 5px rgb(0 0 0 / 3%);",
              mb: 4,
            }}
          >
            <Table sx={{ minWidth: 320 }} aria-label="simple table">
              <TableBody>
                {courseData.overview.priceList.map((row) => (
                  <TableRow
                    hover
                    key={row.title}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {row.title}
                    </StyledTableCell>
                    <StyledTableCell>
                      <strong>{row.value}</strong>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <Heading tag="h5" title={"Programme Structure"} />
        {courseData.overview.courseContent?.map((course, index) => (
          <div className="instructor-item" key={index}>
            <Row>
              <Col md="12">
                <div className="instructor-content">
                  <div className="instructor-box">
                    <div className="top-content d-flex justify-content-between">
                      <div className="instructor-name">
                        <h6>{course?.courseTitle}</h6>
                      </div>
                    </div>
                    <div className="instructor-desk">
                      <p className="instructor-desk-text">
                        {course.description}
                      </p>
                      <ul className="list-unstyled">
                        {course?.content?.map((desc, index) => (
                          <li key={index}>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={24}
                              color={colors.bg1}
                            />
                            <p>{desc}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};
