import { isNull, isUndefined } from "lodash";
import { getCategories } from "../repository/categoryRepository";

export default {
  getCategories: async ({ currentPage, pageSize, ids }) => {
    const pageNumber =
      isNull(currentPage) || isUndefined(currentPage) ? 1 : currentPage;
    const itemsPerPage =
      isNull(pageSize) || isUndefined(pageSize) ? 20 : pageSize;

    if (ids) {
      return await getCategories({
        ids,
        "current-page": pageNumber,
        "page-size": itemsPerPage,
      });
    }

    return await getCategories({
      "current-page": pageNumber,
      "page-size": itemsPerPage,
    });
  },
};
