import React from "react";
import { useState } from "react";
import { Col, Collapse } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";
import { Icon } from "@iconify/react";
import { colors } from "../../../../components/common/element/elements";

export const TeamSectionAccordion = ({ children, title, icon }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Col md="12" className="mt-4 accordion-item">
      <div
        className={[`accordion-head${isOpen ? " collapsed" : ""} d-flex`]}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div
          className={`d-flex justify-content-between align-items-center w-100 accordion-title  ${
            isOpen ? "accordion-open" : ""
          }`}
        >
          <div className="d-flex align-items-center">
            <Icon
              icon={icon}
              fontSize={30}
              className="mr-3"
              color={colors.bg2}
            />
            <Heading tag="h5" title={title} />
          </div>
          <div className={`accordion-icon ${isOpen ? "icon-rotate" : ""}`}>
            <Icon
              icon={"ph:arrow-circle-right-duotone"}
              color={colors.bg2}
              fontSize={30}
            />
          </div>
        </div>
      </div>
      <Collapse
        className={`accordion-body ${isOpen ? "show" : ""}`}
        isOpen={isOpen}
      >
        <div className="accordion-inner">{children}</div>
      </Collapse>
    </Col>
  );
};
