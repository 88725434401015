import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import EnglishData from "../../data/international/international.json";
import RussianData from "../../data/international/international-ru.json";
import UzbekData from "../../data/international/international-uz.json";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { Styles } from "./styles/international";
import Heading from "./../../components/shared/Heading";

const International = () => {
  const { t } = useTranslation();
  const [internationalData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper international-page">
      <BreadcrumbBox
        title="International"
        image="/assets/images/international-1.jpg"
      />
      <Styles>
        <section className="international-area">
          <Container>
            <Row>
              <Col xs="12">
                <div>
                  <div>
                    {internationalData?.description?.map((desc, i) => (
                      <p key={desc} className="international-desc mb-3">
                        {i === 0 ? (
                          <>
                            <span>{desc}</span>
                            <a href="mailto:international.office@pharmatechuni.uz">
                              international.office@pharmatechuni.uz
                            </a>
                          </>
                        ) : i === 2 ? (
                          <>
                            <span>{desc[0]}</span>
                            <a
                              href="https://drive.google.com/file/d/1HtrOV_vZI7hS3up3wNV5WWWYrYXCq2z-/view"
                              target="_blank"
                            >
                              {desc[1]}
                            </a>
                            <span>{desc[2]}</span>
                          </>
                        ) : (
                          desc
                        )}
                      </p>
                    ))}
                  </div>

                  <div id="arrival" className="pt-5 international-data">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={internationalData?.dataList[0]?.title}
                    />
                    <div>
                      {internationalData?.dataList[0]?.descriptions?.map(
                        (desc, index) => (
                          <p className="data-desc" key={index}>
                            {desc}
                          </p>
                        )
                      )}
                    </div>
                  </div>

                  <div id="registration" className="pt-5 international-data">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={internationalData?.dataList[1]?.title}
                    />
                    <div>
                      {internationalData?.dataList[1]?.descriptions?.map(
                        (desc, index) => (
                          <p className="data-desc" key={index}>
                            {desc}
                          </p>
                        )
                      )}
                    </div>
                  </div>

                  <div id="social" className="pt-5 international-data">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={internationalData?.dataList[2]?.title}
                    />
                    <div>
                      {internationalData?.dataList[2]?.descriptions?.map(
                        (desc, index) => (
                          <p className="data-desc" key={index}>
                            {desc}
                          </p>
                        )
                      )}
                    </div>
                  </div>

                  <div id="living" className="living pt-5">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={internationalData?.living.title}
                    />
                    <p className="data-desc">
                      {internationalData?.living.description[0]}
                      <a href="https://www.travelriskmap.com/#/planner/locations">
                        International SOS Risk Map
                      </a>
                      {internationalData?.living.description[1]}
                    </p>
                    <div className="living-map">
                      <img
                        className="living-map"
                        src="/assets/images/international-living-1.png"
                        alt="International living map"
                      />
                    </div>
                    <div className="living-map">
                      <img
                        className="living-map"
                        src="/assets/images/international-living-2.png"
                        alt="International living map"
                      />
                    </div>
                    <p className="data-desc living-map-text">
                      {internationalData?.living.description[2]}
                      <a href="https://www.numbeo.com/cost-of-living/rankings_by_country.jsp">
                        Numbeo’s
                      </a>
                      {internationalData?.living.description[3]}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs="12" className="text-center">
                <div className="guide-link">
                  <a
                    className="px-5"
                    href="https://drive.google.com/file/d/1HtrOV_vZI7hS3up3wNV5WWWYrYXCq2z-/view"
                    target="_blank"
                  >
                    {t("Guide for international students")}
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default International;
