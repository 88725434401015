//PTU
import PTU_Admission_Policy from "./PTU/PTU Admission Policy Eng.pdf";
import PTU_Students_Voice_Management from "./PTU/PTU Students Voice Management.pdf";
import PTU_Student_Complaints_Procedure from "./PTU/Student Complaints Procedure.pdf";

//UOS
import Academic_Misconduct_UOS_Student_Guide from "./UOS/Academic Misconduct UoS STUDENT GUIDE.pdf";
import Extension_EC_UOS_Student_Guide from "./UOS/Extension_EC UoS STUDENT GUIDE.pdf";

//DMU
import Academic_Misconduct_DMU_Student_Guide from "./DMU/Academic Misconduct DMU  STUDENT GUIDE.pdf";
import Extension_Deferral_DMU_Student_Guide from "./DMU/Extension_Deferral_DMU STUDENT GUIDE.pdf";

export const PTU_Regulations = [
  {
    title: "Admission Policy",
    document: PTU_Admission_Policy,
    documentName: "Admission Policy Eng.pdf",
  },
  {
    title: "Students Voice Management",
    document: PTU_Students_Voice_Management,
    documentName: "Students Voice Management.pdf",
  },
  {
    title: "Student Complaints Procedure",
    document: PTU_Student_Complaints_Procedure,
    documentName: "Student Complaints Procedure.pdf",
  },
];

export const DMU_Regulations = [
  {
    title: "Academic Misconduct DMU Student Guide",
    document: Academic_Misconduct_DMU_Student_Guide,
    documentName: "Academic Misconduct DMU  STUDENT GUIDE.pdf",
  },
  {
    title: "Extension Deferral DMU Student Guide",
    document: Extension_Deferral_DMU_Student_Guide,
    documentName: "Extension_Deferral_DMU STUDENT GUIDE.pdf",
  },
];

export const UOS_Regulations = [
  {
    title: "Academic Misconduct UOS Student Guide",
    document: Academic_Misconduct_UOS_Student_Guide,
    documentName: "Extension_EC UoS STUDENT GUIDE.pdf",
  },
  {
    title: "Extension EC UOS Student Guide",
    document: Extension_EC_UOS_Student_Guide,
    documentName: "Academic Misconduct UoS STUDENT GUIDE.docx",
  },
];
