import React from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./Button.css";
import { Icon } from "@iconify/react";

const Button = ({
  className,
  text,
  onClick = () => {},
  type,
  iconName,
  isLoading,
  style,
  outline = false,
  icon,
  wrapperClassName = "",
  noScale,
  eventName,
}) => {
  const { t } = useTranslation();

  const handleButtonClick = (event) => {
    if (type !== "submit") {
      event.preventDefault();
    }

    onClick();
    ReactGA.event({ category: "Button", action: eventName ?? text });
  };

  return (
    <motion.div
      whileHover={{ scale: noScale ? 1 : 1.03 }}
      whileTap={{ scale: 0.92 }}
      className={`btn-wrapper ${wrapperClassName}`}
    >
      <button
        type={type}
        className={`${outline ? "outline" : "button"} ${className}`}
        onClick={handleButtonClick}
        disabled={isLoading}
        style={style}
      >
        {isLoading ? (
          <Spinner animation="border" variant="light" />
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            {iconName ? (
              <Icon icon={iconName} fontSize={20} className="mr-2" />
            ) : null}
            <p className="button-text mb-0">{t(text)}</p>
          </div>
        )}
      </button>
    </motion.div>
  );
};

export default Button;
