import React from "react";

import Button from "../../../components/shared/Button";
import Heading from "../../../components/shared/Heading";
import ListItemWithIconAndValue from "../../../components/shared/ListItemWithIconAndValue";

const CourseDetailsFeatureComponent = ({
  startDate,
  duration,
  language,
  handleNavigate,
}) => {
  return (
    <div className="course-details-feature">
      <Heading tag="h5" className="title" title={"Programme Details"} />
      <ul className="list-unstyled feature-list">
        <ListItemWithIconAndValue
          iconName={"ph:calendar-duotone"}
          title={"Start Date"}
          value={startDate}
        />
        <ListItemWithIconAndValue
          iconName={"ph:clock-duotone"}
          title={"Duration"}
          value={duration}
        />
        <ListItemWithIconAndValue
          iconName={"ph:globe-duotone"}
          title={"Language"}
          value={language}
        />
      </ul>
      <Button
        type="button"
        className="enroll-btn"
        wrapperClassName={"enroll-btn-wrapper"}
        text={"Apply Now"}
        onClick={handleNavigate}
      />
    </div>
  );
};

export default CourseDetailsFeatureComponent;
