import { useCallback } from "react";

import categoryService from "../service/categoryService";
import { useNewsPageContext } from "./useNewsPageContext";

const useFetchCategories = () => {
  const { newsDispatch } = useNewsPageContext();

  const fetchCategories = useCallback(
    async () =>
      await categoryService.getCategories({
        pageSize: 100,
      }),
    []
  );

  const handleOnLoadFetchedCategories = useCallback(
    (response) =>
      newsDispatch({ type: "categoriesFetch", response: response?.data }),
    [newsDispatch]
  );

  return [fetchCategories, handleOnLoadFetchedCategories];
};

export default useFetchCategories;
