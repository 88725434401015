import React from "react";
import { motion } from "framer-motion";

const IconButton = ({
  icon,
  iconName,
  onClick,
  className,
  wrapperClassName,
  color,
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.92 }}
      className={`btn-wrapper ${wrapperClassName}`}
    >
      {icon ? (
        <img src={icon} alt="icon" className="icon-button" onClick={onClick} />
      ) : null}
      {iconName ? (
        <i
          className={` las la-${iconName}`}
          onClick={onClick}
          style={{ fontSize: "24px", cursor: "pointer", color: color }}
        ></i>
      ) : null}
    </motion.div>
  );
};

export default IconButton;
