import moment from "moment";
import momentTimezone from "moment-timezone";
import { DATE_FORMATS } from "./constants";

export const getCurrentEpochSeconds = (currentDate) => {
  const date = new Date(currentDate);
  return Math.round(date.getTime() / 1000);
};

export const covnvertSecondsToMilliseconds = (epochSeconds) =>
  epochSeconds * 1000;

export const unixDateConvertor = (
  unixDate,
  dateFormat = DATE_FORMATS.DATE_REGISTERED
) => {
  return moment(unixDate).format(dateFormat);
};

export const getCurrentDate = (dateFormat, currentDate) => {
  if (!currentDate) {
    return moment(new Date()).format(dateFormat);
  }

  return moment(new Date(currentDate)).format(dateFormat);
};

export const getCurrentLocalDate = (dateFormat, currentDate) => {
  if (!currentDate) {
    return momentTimezone(new Date()).tz("Asia/Tashkent").format(dateFormat);
  }

  return momentTimezone(new Date(currentDate))
    .tz("Asia/Tashkent")
    .format(dateFormat);
};

export const getPreviousDay = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return previous;
};
