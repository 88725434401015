import styled from "styled-components";

export const Styles = styled.div`
  .social-area {
    .social-content {
      padding: 32px 0;

      .par {
        font-size: 16px;
        line-height: 28px;
        color: #666;
        padding-bottom: 16px;
      }
    }
  }
`;
