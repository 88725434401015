import styled from "styled-components";
import { colors } from "../../../components/common/element/elements";

export const Styles = styled.div`
  .student-information-support-area {
    .student-information-support-content {
      padding: 32px 0;

      .par {
        font-size: 16px;
        line-height: 28px;
        color: #666;
        padding-bottom: 16px;
      }
    }
  }

  .title {
    font-size: 24px;
    line-height: 40px;
    color: ${colors.text1};
    padding-bottom: 12px;
    margin-bottom: 30px;
    border-bottom: 1px solid ${colors.border1};
  }

  ul {
    li {
      display: flex;
      align-items: center;
      column-gap: 10px;
      border-radius: 8px;
      box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
      padding: 10px 15px;
      font-size: 15px;
      color: ${colors.text3};
      line-height: 25px;
      margin-bottom: 15px;
    }

    li.job {
      i {
        color: ${colors.bg1};
        font-size: 20px;
      }
    }
  }
`;
