import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

import Button from "../shared/Button";

import { useStatusContext } from "../../hooks/contextHooks/useStatusContext";
import Heading from "../shared/Heading";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [onFetch, isLoading] = useBeManualFetcher();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const sendEmail = () => {
    return onFetch({
      action: () =>
        emailjs.sendForm(
          "service_vicexzx",
          "template_04fwt25",
          form.current,
          "Mx9_X5dJxlj27y7ez"
        ),
      onLoad: () => form.current.reset(),
      successMessage: "Message sent!",
    });
  };

  return (
    <div className="contact-form">
      <div className="form-title">
        <Heading tag="h4" title={"Get In Touch"} />
      </div>
      <div className="form-box">
        <form
          id="form_contact"
          className="form"
          onSubmit={handleSubmit(sendEmail)}
          ref={form}
        >
          <Row>
            <Col md="6">
              <p className="form-control">
                <input
                  type="text"
                  placeholder={t("Full Name")}
                  name="fullName"
                  id="contact_name"
                  {...register("fullName", {
                    required: t("Field cannot be empty"),
                    minLength: {
                      value: 2,
                      message: t(
                        "Minimum length should be more than 2 characters"
                      ),
                    },
                  })}
                />
                <span className="contact_input-msg">
                  {errors?.fullName?.message}
                </span>
              </p>
            </Col>
            <Col md="6">
              <p className="form-control">
                <input
                  type="email"
                  placeholder={t("Email Address")}
                  name="email"
                  id="contact_email"
                  {...register("email", {
                    required: t("Field cannot be empty"),
                    minLength: {
                      value: 5,
                      message: t(
                        "Minimum length should be more than 5 characters"
                      ),
                    },
                  })}
                />
                <span className="contact_input-msg">
                  {errors?.email?.message}
                </span>
              </p>
            </Col>
            <Col md="12">
              <p className="form-control">
                <input
                  type="text"
                  placeholder={t("Subject")}
                  name="subject"
                  id="contact_subject"
                  {...register("subject", {
                    required: t("Field cannot be empty"),
                    minLength: {
                      value: 5,
                      message: t(
                        "Minimum length should be more than 5 characters"
                      ),
                    },
                  })}
                />
                <span className="contact_input-msg">
                  {errors?.subject?.message}
                </span>
              </p>
            </Col>
            <Col md="12">
              <p className="form-control">
                <textarea
                  name="message"
                  id="contact_message"
                  placeholder={t("Enter Message")}
                  {...register("message", {
                    required: t("Field cannot be empty"),
                    minLength: {
                      value: 10,
                      message: t(
                        "Minimum length should be more than 10 characters"
                      ),
                    },
                  })}
                ></textarea>
                <span className="contact_input-msg">
                  {errors?.message?.message}
                </span>
              </p>
            </Col>
            <Col md="12">
              <Button
                type="submit"
                text={"Send Message"}
                wrapperClassName={"mt-2"}
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
