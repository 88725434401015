import React from "react";
import { motion } from "framer-motion";

import { useCurrentLanguage } from "../../../hooks/useCurrentLanguage";

const LatestNewsBlockComponent = ({
  image,
  categories,
  title,
  onClick,
  className,
}) => {
  const [language] = useCurrentLanguage();

  return (
    <motion.div
      whileHover={{ scale: 1.01 }}
      className={className}
      style={{
        backgroundImage: `url(${image})`,
      }}
      onClick={onClick}
    >
      <div className="news-category-wrapper">
        {categories?.map((category) => (
          <div className="news-title-wrapper" key={category?.id}>
            <h5>{category?.title?.[language]}</h5>
          </div>
        ))}
      </div>
      <div
        className="bottom-gradient"
        style={{
          backgroundImage:
            "linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0))",
        }}
      >
        <article>{title}</article>
      </div>
    </motion.div>
  );
};

export default LatestNewsBlockComponent;
