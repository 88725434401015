import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import PopularCourse from "./components/PopularCourse";
import DetailsPageTabInfoBar from "./components/DetailsPageTabInfoBar";
import CourseDetailsFeatureComponent from "./components/CourseDetailsFeatureComponent";

import { useLanguageDetector } from "../../hooks/useLanguageDetector";

import EnglishData from "../../data/course/filter.json";
import RussianData from "../../data/course/filter-rus.json";
import UzbekData from "../../data/course/filter-uz.json";

import { Styles } from "./styles/course.js";
import { CPDCourseContent } from "./components/CourseDetailsTypes/CPDCourseContent.jsx";
import { UniversityProgrammeContent } from "./components/CourseDetailsTypes/UniversityProgrammeContent.jsx";
import { PreFoundationProgrammeContent } from "./components/CourseDetailsTypes/PreFoundationProgrammeContent.jsx";

const CourseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const [courseData, setCourseData] = useState({});

  useEffect(() => {
    const currentData = data?.dataList?.find((course) => course?.id === id);
    setCourseData(currentData);
  }, [id, data]);

  const courseDetailsByType = useMemo(() => {
    return {
      cpd: <CPDCourseContent courseData={courseData} />,
      university_programme: (
        <UniversityProgrammeContent courseData={courseData} />
      ),
      pre_foundation: <PreFoundationProgrammeContent courseData={courseData} />,
    };
  }, [courseData]);

  useEffect(() => {
    const courseButton = document.querySelectorAll(".course-button");
    courseButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "course-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "course-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  const handleOnApplyClick = (courseEnum) => {
    if (courseEnum === "CPD_COURSES") {
      window.open(courseData.applyLink, "_blank", "rel=noopener noreferrer");
      return;
    }

    return navigate(`/students-apply-form?programme=${courseEnum}`, {
      state: {
        facultyType: courseEnum,
      },
    });
  };

  return (
    <div className="main-wrapper course-details-page">
      <BreadcrumbBox
        title="Programme Details"
        image="/assets/images/banner-5.jpg"
      />
      <Styles>
        <section className="course-details-area">
          <Container>
            <Row>
              <Col lg="9" md="8" sm="12">
                <div className="course-details-top">
                  <div className="heading">
                    <h4>{courseData?.courseTitle}</h4>
                  </div>
                  <DetailsPageTabInfoBar
                    courseLeader={courseData?.courseLeader}
                    courseCategory={courseData?.courseCategory}
                    price={courseData?.price}
                  />
                  <div className="course-details-banner">
                    <img
                      src={`/assets/images/${courseData?.detailsImage}`}
                      alt="Course details image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="course-tab-list">
                    {courseDetailsByType[courseData.type]}
                  </div>
                </div>
              </Col>
              <Col lg="3" md="4" sm="12">
                <div className="single-details-sidbar">
                  <Row>
                    <Col md="12">
                      <CourseDetailsFeatureComponent
                        startDate={courseData?.startDate}
                        duration={courseData?.duration}
                        language={courseData?.language}
                        handleNavigate={() =>
                          handleOnApplyClick(courseData.courseEnum)
                        }
                      />
                    </Col>
                    <Col md="12">
                      <PopularCourse courseData={data} />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default CourseDetails;
