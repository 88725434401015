import React from "react";
import { CircularProgress } from "@mui/material";

import "./SpinnerComponent.css";

const SpinnerComponent = ({ height, width, size }) => {
  return (
    <div
      className="SpinnerComponent"
      style={{ height: height ?? "60vh", width: width }}
    >
      <CircularProgress color="inherit" />
    </div>
  );
};

export default SpinnerComponent;
