import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";
import { SampleDocument } from "../../../courses/components/CourseDetailSampleExamTab";
import { useTranslation } from "react-i18next";

export const OnlineLinksSection = () => {
  const { t } = useTranslation();
  return (
    <section className="programme" id="online">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"For Online Attandees"}
              />
              <SampleDocument
                title={"ICPL Day One (7th November 2024)"}
                url={
                  "https://us06web.zoom.us/j/87202602994?pwd=TofrgNU1vPQVhbes49SGbwzVQbP1WM.1"
                }
                buttonText={t("Click")}
              />
              <SampleDocument
                title={"ICPL Day Two (8th November 2024)"}
                url={
                  "https://us06web.zoom.us/j/86020549243?pwd=zRNxBlOLpwAf3vs6v1o1JU8mM3Oxvi.1"
                }
                buttonText={t("Click")}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
