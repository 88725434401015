export const throwHttpError = ({
  statusCode,
  beErrorCode,
  message,
  errors,
}) => ({
  statusCode,
  beErrorCode,
  message,
  errors,
});
