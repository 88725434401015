import styled from "styled-components";

export const Styles = styled.div`
  .tuition-fees {
    padding: 32px 0;
    .tuition-fees-content {
      .par {
        font-size: 16px;
        line-height: 28px;
        color: #666;
        padding-bottom: 16px;
      }
    }
  }

  .tuition {
    margin-bottom: 32px;
  }

  .domestic-tuition,
  international-tuition {
    margin-bottom: 32px;
  }

  table {
    margin: 32px 0;
  }

  table {
    display: block;
    overflow-x: auto !important;
  }

  th,
  td {
    padding: 12px 24px;
    vertical-align: middle !important;
  }

  th {
    background-color: #f2f2f2;
    font-weight: 600;
    text-align: center;
  }

  td:last-child,
  td:nth-child(2) {
    text-align: center;
  }

  @media (max-width: 768px) {
    .tuition-fees {
      padding: 24px 0;
    }
    table {
      font-size: 14px;
    }
    th,
    td {
      padding: 4px 8px;
    }
  }
`;
