import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .registration-page {
    .registration-area {
      padding: 50px 0;
      .registration-box {
        max-width: 1000px;
        margin: auto;
        border: 1px solid ${colors.border1};
        box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
        padding: 25px 30px;
        border-radius: 8px;
        .registration-title {
          h3 {
            color: ${colors.black2};
            text-transform: uppercase;
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              background: ${colors.bg1};
              width: 50px;
              height: 2px;
              bottom: 0;
              left: 50%;
              margin-left: -25px;
            }

            @media (max-width: 575px) {
              font-size: 20px;
            }
          }
          .registration-note {
            padding: 15px;
            transition: all 0.2s ease;
            overflow: hidden;
            box-shadow: 0 1px 4px 0 rgb(0 0 0 / 0.1),
              0 1px 2px -1px rgb(0 0 0 / 0.1);
            background-color: ${colors.bg1};
            color: ${colors.text6};
            font-weight: 500;
            font-size: 14px;
            border-radius: 8px;
            margin: 20px 0;
            a {
              font-weight: 700;
              color: ${colors.bg2};
              :hover {
                text-decoration: underline;
              }
            }
          }
        }

        form.form {
          .form-title {
            font-size: 15px;
            color: ${colors.text1};
            font-weight: 500;

            @media (max-width: 575px) {
              font-size: 14px;
            }
          }
          .form-control {
            padding: 0;
            width: auto;
            height: auto;
            background: transparent;
            border: none;
            margin-bottom: 15px;
            position: relative;

            .select {
              .css-13cymwt-control {
                border-radius: 8px;
                border: 1px solid #dddddd;
                outline: none;
              }
            }

            .info {
              background-color: ${colors.text3};
              padding: 3px 8px;
              border-radius: 50%;
              margin-bottom: 0.5rem;
              cursor: pointer;

              :hover {
                background-color: ${colors.bg1};
              }

              .personal-state-information {
                width: 50vw;
                background: rgb(102 42 48 / 85%);
                box-shadow: 0 8px 32px rgb(31 31 31 / 37%);
                -webkit-backdrop-filter: blur(2px);
                backdrop-filter: blur(2px);
                color: white;
                border-radius: 8px;
                padding: 15px;

                &-list-item {
                  margin-bottom: 5px;
                }
              }

              .photo-information {
                width: 30vw;
                background: rgb(102 42 48 / 85%);
                box-shadow: 0 8px 32px rgb(31 31 31 / 37%);
                -webkit-backdrop-filter: blur(2px);
                backdrop-filter: blur(2px);
                color: white;
                border-radius: 8px;
                padding: 15px;
              }
            }

            label {
              font-size: 15px;
              color: ${colors.text1};
              font-weight: 500;

              strong {
                color: ${colors.black1};
              }

              @media (max-width: 575px) {
                font-size: 14px;
              }
            }

            input {
              width: 100%;
              height: 40px;
              background-color: #ffffff;
              font-size: 14px;
              padding: 10px 20px;
              color: ${colors.black1};
              border: 1px solid ${colors.border3};
              border-radius: 8px;

              &::placeholder {
                font-size: 14px;
                font-style: italic;
                color: ${colors.text3};

                @media (max-width: 575px) {
                  font-size: 13px;
                }
              }

              &:focus {
                border-color: ${colors.bg1};
              }

              @media (max-width: 575px) {
                height: 40px;
              }
            }

            select {
              width: 100%;
              height: 40px;
              background-color: #ffffff;
              font-size: 14px;
              padding: 10px 20px;
              color: ${colors.black1};
              border: 1px solid ${colors.border3};
              border-radius: 8px;

              &::placeholder {
                font-size: 14px;
                font-style: italic;
                color: ${colors.text3};

                @media (max-width: 575px) {
                  font-size: 13px;
                }
              }

              &:focus {
                border-color: ${colors.bg1};
              }

              @media (max-width: 575px) {
                height: 40px;
              }
            }

            span {
              color: ${colors.red};
              font-weight: 300;
              position: absolute;
              bottom: -20px;
              left: 0;
              visibility: hidden;
            }
          }

          .custom-radio {
            display: flex;
            justify-content: start;
            div {
              display: flex;
              align-items: center;
              margin-top: 10px;
              input {
                width: 18px;
                height: 18px;
                margin-bottom: 0;
              }

              label {
                font-weight: 400;
                font-size: 14px;
                line-height: 1.5;
                letter-spacing: 0.00938em;
                margin-right: 5px;
                margin-bottom: 0;
              }
            }
          }

          .form-control.success {
            input {
              border: 2px solid ${colors.green};
            }

            &::before {
              position: absolute;
              content: "\f058";
              font-family: "Line Awesome Free";
              font-size: 24px;
              color: ${colors.green};
              font-weight: 900;
              top: 34px;
              right: 10px;
            }
          }

          .form-control.error {
            input {
              border: 2px solid ${colors.red};
            }

            &::before {
              position: absolute;
              content: "\f06a";
              font-family: "Line Awesome Free";
              font-size: 24px;
              color: ${colors.red};
              font-weight: 900;
              top: 34px;
              right: 10px;
            }
          }

          .form-control.error {
            span {
              visibility: visible;
            }
          }

          button {
            font-size: 16px;
            color: #fff;
            background: ${colors.gr_bg};
            width: 100%;
            height: 48px;
            font-weight: 500;
            border: none;
            border-radius: 8px;
            text-transform: uppercase;
            margin-bottom: 20px;

            &:hover {
              background: ${colors.gr_bg2};

              i {
                color: #ffffff;
              }
            }

            @media (max-width: 575px) {
              font-size: 15px;
              height: 40px;
            }
          }
        }

        .have_account-btn {
          p {
            font-size: 14px;
            color: ${colors.text3};
            a {
              font-size: 14px;
              color: ${colors.bg1};
              font-weight: 500;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .container {
        .row {
          .apply-navigation {
            .right {
              border-radius: 8px 0 0 8px;
              background: white;
              cursor: pointer;
              color: ${colors.black1};
              font-weight: 500;
              box-shadow: 0 8px 25px rgb(0 0 0 / 10%);
              white-space: nowrap;
            }
            .left {
              border-radius: 0 8px 8px 0;
              background: white;
              cursor: pointer;
              color: ${colors.black1};
              font-weight: 500;
              box-shadow: 0 8px 25px rgb(0 0 0 / 10%);
              white-space: nowrap;
            }
            .apply-navigation-active {
              color: white;
              background: #662a30;
              font-weight: 500;
              white-space: nowrap;
            }
          }
          div {
            div {
              .vacancy-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;
                transition: all 0.2s ease;
                overflow: hidden;
                margin-bottom: 15px;
                padding: 15px 20px;
                box-shadow: 0 5px 25px rgb(0 0 0 / 10%);
                .vacancy-title {
                  color: ${colors.black1};
                  font-weight: 600;
                }

                .apply-button-wrapper {
                  display: flex;
                  column-gap: 10px;
                  .apply-button {
                    font-size: 14px;
                    color: #fff;
                    border: none;
                    background: ${colors.gr_bg};
                    text-align: center;
                    border-radius: 8px;

                    &:hover {
                      background: ${colors.gr_bg2};
                    }

                    @media (max-width: 767px) {
                      margin-bottom: 5px;
                    }
                  }
                }
                @media (max-width: 767px) {
                  flex-direction: column;
                  row-gap: 20px;
                }
              }

              .course-item {
                border-radius: 8px;
                transition: all 0.2s ease;
                overflow: hidden;
                margin-bottom: 30px;
                box-shadow: 0 8px 25px rgb(0 0 0 / 10%);

                .apply-button-wrapper {
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  margin: 20px 0;
                }

                .course-image {
                  width: 100%;
                  height: 220px;
                  background-size: cover;
                  background-position: center;
                  background-repeat: no-repeat;
                  border-radius: 8px 8px 0 0;
                  position: relative;

                  @media (max-width: 767px) {
                    height: 280px;
                  }
                }
              }

              .course-content {
                background: #fff;
                padding: 20px 25px 15px;
                height: 80px;
                text-align: center;
                h6.heading {
                  color: ${colors.black1};
                  font-weight: 400;
                  display: inline-block;
                  margin-bottom: 12px;
                  span {
                    font-weight: 600;
                  }
                }

                @media (max-width: 992px) {
                  height: 115px;
                }

                @media (max-width: 767px) {
                  height: 70px;
                }

                @media (max-width: 300px) {
                  height: 115px;
                }
              }
            }
          }
        }
      }
      @media (max-width: 767px) {
        padding: 20px 0;
      }
    }
  }
  .custom-checkbox {
    display: flex;
    align-items: center;
    padding-top: 16px;

    input {
      display: block;
      width: 20%;
      height: 20px;
      margin-right: 12px;
    }

    label {
      margin-bottom: 0;
      font-size: 14px;
      color: ${colors.black1};
    }
  }
`;
