import styled from "styled-components";
import { colors } from "../common/element/elements.js";

export const Styles = styled.div`
  .icon-box-area {
    padding-bottom: 120px;

    .full-icon-box {
      position: relative;
      z-index: 10;

      .icon-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        position: absolute;
        top: -100px;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 30px 20px 20px;
        z-index: 9;
        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        border-radius: 8px;
        height: 240px;

        .box-icon {
          text-align: center;
          position: relative;

          img {
            @media (max-width: 1199px) {
              width: 60px;
            }

            @media (max-width: 992px) {
              width: 45px;
            }
          }

          i {
            font-size: 80px;
            color: ${colors.bg1};
            height: 80px;
            display: inline-block;
            position: relative;
            z-index: 111;

            @media (max-width: 991px) {
              font-size: 24px;
              width: 45px;
            }

            @media (max-width: 767px) {
              font-size: 34px;
              width: 60px;
            }
          }
        }

        h6 {
          color: ${colors.black1};
          font-weight: 600;
          text-transform: "capitalize";
          text-align: center;
          margin-top: 10px;
          margin-bottom: 5px;

          @media (max-width: 991px) {
            font-size: 12px;
            letter-spacing: 0;
          }

          @media (max-width: 767px) {
            margin-top: 10px;
            font-size: 16px;
            letter-spacing: 0.3px;
          }
        }

        p {
          font-size: 14px;
          color: ${colors.text3};
          text-align: center;
          line-height: 24px;
          font-weight: 500;

          @media (max-width: 991px) {
            font-size: 11px;
            line-height: 15px;
          }

          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        @media (max-width: 1199px) {
          height: 245px;
        }

        @media (max-width: 991px) {
          padding: 25px 10px 8px;
          height: 175px;
        }

        @media (max-width: 767px) {
          padding: 35px 20px;
          position: unset;
          height: 100%;
          margin-bottom: 25px;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 40px 0 0;
    }
  }
`;
