import styled from "styled-components";

export const Styles = styled.div`
  .student-life {
    padding: 32px 0;
    .student-life-content {
      .par {
        font-size: 16px;
        line-height: 28px;
        color: #666;
        padding-bottom: 16px;
      }
    }
  }
`;
