import { Skeleton } from "@mui/material";
import React from "react";

const LatestNewsSkeleton = () => {
  return (
    <div>
      <Skeleton
        variant="rounded"
        width={300}
        height={38}
        className="mb-4"
        animation="wave"
      />
      <div className="latest-news-container">
        <Skeleton
          variant="rounded"
          className="latest-news-taller"
          animation="wave"
        />
        <div className="latest-news-wider">
          <Skeleton
            className="latest-news-wider-rectangle"
            variant="rounded"
            animation="wave"
          />
          <div className="latest-news-wider-squares-wrapper">
            <Skeleton
              className="latest-news-wider-square"
              variant="rounded"
              animation="wave"
            />
            <Skeleton
              className="latest-news-wider-square"
              animation="wave"
              variant="rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestNewsSkeleton;
