import styled from "styled-components";
import { colors } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .news-area {
    padding: 40px 0;

    h4.news-title {
      color: #444444;
      font-weight: 600;
      padding-bottom: 10px;
      margin-bottom: 20px;
      position: relative;

      :before {
        position: absolute;
        content: "";
        background: #662a30;
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;

        @media (max-width: 767px) {
          width: 30px;
        }
      }

      @media (max-width: 767px) {
        font-size: 16px;
        padding-bottom: 7.5px;
      }
    }

    .news-category-wrapper {
      position: absolute;
      right: 15px;
      top: 15px;
      display: flex;
      column-gap: 5px;

      .news-title-wrapper {
        border-radius: 24px;
        padding: 5px 10px;
        color: ${colors.black1};
        background-color: ${colors.text6};

        h5 {
          color: ${colors.bg1};
          font-size: 14px;
          z-index: 20;

          @media (max-width: 767px) {
            font-size: 11px;
          }
        }

        @media (max-width: 767px) {
          right: 12px;
          top: 12px;
          padding: 3.5px 7px;
        }
      }
    }

    .bottom-gradient {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 8px;

      article {
        position: absolute;
        bottom: 15px;
        left: 20px;
        font-size: 17px;
        max-width: 80%;
        color: ${colors.text6};

        @media (max-width: 767px) {
          bottom: 17px;
          left: 15px;
          font-size: 16px;
        }
      }
    }

    .view-more-button-wrapper {
      margin-bottom: 30px;
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 20px;

      i {
        color: ${colors.bg1};
        font-size: 16px;
      }
    }

    .news-list-item {
      width: 100%;
      box-shadow: 0 8px 20px 5px rgb(0 0 0 / 7%);
      border-radius: 8px;
      margin-bottom: 20px;
      transition: 150ms;
      cursor: pointer;

      .news-list-image {
        width: 100%;
        height: 389px;
        border-radius: 8px 8px 0 0;
        object-fit: cover;

        @media (max-width: 1200px) {
          height: 343px;
        }

        @media (max-width: 992px) {
          height: 253px;
        }

        @media (max-width: 400px) {
          height: 150px;
        }
      }

      .news-list-content-wrapper {
        padding: 30px;

        @media (max-width: 400px) {
          padding: 17px;
        }

        .news-list-header {
          font-size: 20px;
          margin-bottom: 20px;
          color: ${colors.black1};

          @media (max-width: 400px) {
            font-size: 15px;
          }
        }

        .news-list-details-wrapper {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          row-gap: 10px;

          .news-list-news-date {
            display: flex;
            align-items: center;
            column-gap: 5px;

            i {
              font-size: 20px;
              color: ${colors.bg1};
            }

            p {
              font-size: 14px;
              font-weight: 500;
              color: ${colors.black1};
            }
          }

          .news-list-news-category-wrapper {
            display: flex;
            align-items: center;
            column-gap: 5px;

            h5.news-list-news-category {
              display: flex;
              align-items: center;
              color: #662a30;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .news-list-side-panel {
      margin-top: 58px;
      height: 100%;
      .news-side-panel-wrapper {
        padding: 20px;
        box-shadow: 0 8px 20px 5px rgb(0 0 0 / 7%);
        border-radius: 8px;
        margin-bottom: 20px;

        .news-side-panel-title {
          color: ${colors.black1};
          font-weight: 600;
          padding-bottom: 10px;
          margin-bottom: 20px;
          position: relative;

          :before {
            position: absolute;
            content: "";
            background: #662a30;
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
          }
        }

        :nth-child(2) {
          position: sticky;
          top: 120px;
          margin-bottom: 78px;
        }
      }

      .news-category-panel-item {
        padding: 10px 15px;
        border-bottom: 1px solid ${colors.border3};
        font-size: 15px;
        font-weight: 500;
        color: ${colors.black1};
        cursor: pointer;

        :hover {
          color: ${colors.bg2};
        }

        :last-child {
          border: none;
        }
      }

      .news-top-stories-panel-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 10px;
        margin-bottom: 20px;
        cursor: pointer;

        @media (max-width: 992px) {
          flex-wrap: wrap;
        }

        @media (max-width: 768px) {
          flex-wrap: nowrap;
        }

        @media (max-width: 400px) {
          flex-wrap: wrap;
        }

        .news-top-stories-panel-item-img {
          flex: 0 35%;
          width: 100%;
          height: 100px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 8px;

          @media (max-width: 992px) {
            flex: 0 100%;
            margin-bottom: 5px;
          }

          @media (max-width: 768px) {
            flex: 0 35%;
          }

          @media (max-width: 400px) {
            flex: 0 100%;
            margin-bottom: 5px;
          }
        }

        .news-top-stories-panel-item-content {
          flex: 0 65%;
          display: flex;
          flex-direction: column;

          @media (max-width: 992px) {
            flex: 0 100%;
          }

          .news-top-stories-panel-item-title {
            font-weight: 500;
            font-size: 14px;
          }

          .news-top-stories-panel-item-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;

            .news-top-stories-panel-item-date {
              display: flex;
              align-items: center;
              column-gap: 1px;
              font-size: 14px;

              i {
                color: ${colors.bg1};
              }
            }

            .news-top-stories-panel-item-category {
              font-size: 14px;
              color: ${colors.bg1};
              font-weight: 500;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 30px;
    }
  }
`;
