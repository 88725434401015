import isEmpty from "lodash/isEmpty";

export const convertToQueryString = (filterObj) => {
  if (!filterObj) return null;

  return Object.keys(filterObj)
    .map((key) => {
      const objectValue = filterObj[key];

      if (objectValue instanceof Array)
        return objectValue.map((arrayElement) => {
          const queryString = `${key}=${arrayElement}`;

          return objectValue.at(-1) === arrayElement
            ? queryString
            : queryString.concat("&");
        });

      return `${key}=${objectValue}`;
    })
    .join("&")
    .replaceAll(",", "");
};

export const convertQuerySearchToObject = (queryString) => {
  var search = queryString.substring(1);

  if (isEmpty(queryString)) {
    return {};
  }

  return JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    (key, value) => (key === "" ? value : decodeURIComponent(value))
  );
};
