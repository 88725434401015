import React, { useCallback } from "react";
import { motion } from "framer-motion";
import { Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { useNavigate } from "react-router-dom";

import Fetcher from "../../../components/shared/Fetcher";
import Heading from "../../../components/shared/Heading";

import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import { useQueryParamByName } from "../../../hooks/useQueryParamByName";
import newsService from "../../service/newsService";
import { IMAGE_KEYS, LANGUAGE_KEYS } from "../../../utilities/constants";

const HorizontalTopNewsComponent = () => {
  const navigate = useNavigate();
  const { newsState, newsDispatch } = useNewsPageContext();
  const currentPageQueryString = useQueryParamByName("current-page");
  const pageSizeQueryString = useQueryParamByName("page-size");

  const settings = {
    loop: true,
    speed: 1500,
    autoplay: {
      delay: 4000,
      disableOnInteraction: true,
    },
    spaceBetween: 30,
    watchSlidesVisibility: true,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
    },
  };

  const fetchTopNews = useCallback(
    async () =>
      await newsService.getNews({
        isAtTop: true,
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedTopNews = useCallback(
    (response) => {
      newsDispatch({ type: "topNewsFetch", response: response });
    },
    [newsDispatch]
  );

  return (
    <Col md="12" className="top-story-section">
      <Fetcher action={fetchTopNews} onLoad={handleOnLoadFetchedTopNews}>
        <Heading tag="h4" title={"Top Stories"} className="news-title" />
        <div className="top-story-container">
          <Swiper {...settings} className={"d-flex align-items-center"}>
            {newsState?.topNews?.map((news) => (
              <motion.div
                className="top-story"
                whileHover={{ scale: 1.02 }}
                key={news?.id}
                onClick={() => navigate(`/news/${news?.id}`)}
              >
                <div
                  className="top-stories-image"
                  style={{
                    backgroundImage: `url(${
                      news?.imageUrls?.[LANGUAGE_KEYS.EN]?.[
                        IMAGE_KEYS.COVER_IMAGE_GET
                      ]
                    })`,
                  }}
                ></div>
                <h6>{news?.categoryName?.[LANGUAGE_KEYS.EN]}</h6>
                <article>{news.title[LANGUAGE_KEYS.EN]}</article>
              </motion.div>
            ))}
          </Swiper>
        </div>
      </Fetcher>
    </Col>
  );
};

export default HorizontalTopNewsComponent;
