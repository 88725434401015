import React, { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

import Button from "./shared/Button";
import Heading from "./shared/Heading";

import { useBeManualFetcher } from "../hooks/useBeManualFetcher";

import { Styles } from "./styles/homeContact.js";

function HelpArea() {
  const { t } = useTranslation();
  const form = useRef();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  const [onFetch, isLoading] = useBeManualFetcher();

  const sendEmail = async () => {
    return await onFetch({
      action: () =>
        emailjs.sendForm(
          "service_vicexzx",
          "template_04fwt25",
          form.current,
          "Mx9_X5dJxlj27y7ez"
        ),
      onLoad: () => form.current.reset(),
      successMessage: "Message sent!",
    });
  };

  return (
    <Styles>
      <section className="home-contact-area">
        <Container>
          <Row>
            <Col md="12">
              <div className="sec-title text-center">
                <Heading
                  tag="h4"
                  title={
                    "If You have Any Question Please Don't Hesitate To Contact Us"
                  }
                />
              </div>
            </Col>
            <Col md="12">
              <form
                id="form_contact"
                className="form"
                onSubmit={handleSubmit(sendEmail)}
                ref={form}
              >
                <Row>
                  <Col md="4">
                    <p className="form-control">
                      <input
                        type="text"
                        placeholder="Full Name"
                        name="fullName"
                        id="contact_name"
                        {...register("fullName", {
                          required: t("Field cannot be empty"),
                          minLength: {
                            value: 2,
                            message: t(
                              "Minimum length should be more than 2 characters"
                            ),
                          },
                        })}
                      />
                      <span className="contact_input-msg">
                        {errors?.fullName?.message}
                      </span>
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="form-control">
                      <input
                        type="email"
                        placeholder="Email Address"
                        name="email"
                        id="contact_email"
                        {...register("email", {
                          required: t("Field cannot be empty"),
                          minLength: {
                            value: 5,
                            message: t(
                              "Minimum length should be more than 5 characters"
                            ),
                          },
                        })}
                      />
                      <span className="contact_input-msg">
                        {errors?.email?.message}
                      </span>
                    </p>
                  </Col>
                  <Col md="4">
                    <p className="form-control">
                      <input
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        id="contact_subject"
                        {...register("subject", {
                          required: t("Field cannot be empty"),
                          minLength: {
                            value: 5,
                            message: t(
                              "Minimum length should be more than 5 characters"
                            ),
                          },
                        })}
                      />
                      <span className="contact_input-msg">
                        {errors?.subject?.message}
                      </span>
                    </p>
                  </Col>
                  <Col md="12">
                    <p className="form-control">
                      <textarea
                        name="message"
                        id="contact_message"
                        placeholder="Enter Message"
                        {...register("message", {
                          required: t("Field cannot be empty"),
                          minLength: {
                            value: 10,
                            message: t(
                              "Minimum length should be more than 10 characters"
                            ),
                          },
                        })}
                      ></textarea>
                      <span className="contact_input-msg">
                        {errors?.message?.message}
                      </span>
                    </p>
                  </Col>
                  <Col md="12" className="help-area-button-wrapper">
                    <Button
                      text={"Send Request"}
                      type="submit"
                      isLoading={isLoading}
                    />
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Styles>
  );
}

export default HelpArea;
