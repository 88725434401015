import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import VacancyItem from "../../components/career/VacancyItem";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import Heading from "../../components/shared/Heading";

import EnglishData from "../../data/careers/careers.json";
import RussianData from "../../data/careers/careers-rus.json";
import UzbekData from "../../data/careers/careers-uz.json";
import vacancyData from "../../data/vacancy/vacancy.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { Link, useNavigate } from "react-router-dom";
import TeamData from "../../data/team/team.json";

import { Styles } from "./styles/careers.js";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements";

const Team = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const navigate = useNavigate();

  return (
    <Styles>
      <div className="main-wrapper careers-page">
        <BreadcrumbBox title="Our Team" image="/assets/images/faq-2.jpg" />
        <section className="strategy-area">
          <Container>
            <Row>
              <Col md="12">
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={"Our Team"}
                />
              </Col>
              {TeamData.dataList.map((employee) => (
                <Col md="4" className="team-block">
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <Link to={`/team/${employee.id}`}>
                          <img
                            title={employee.name}
                            src={`/assets/images/${employee?.image}`}
                          />
                        </Link>
                      </figure>
                      <span className="share-icon">
                        <Icon
                          color={colors.bg2}
                          icon="ph:share-network-duotone"
                          fontSize={24}
                        />
                      </span>

                      <div className="social-links">
                        <a href={employee.email} target="_blank">
                          <Icon
                            color={colors.bg2}
                            icon="ph:envelope-simple-duotone"
                            fontSize={24}
                          />
                        </a>
                        <a href={employee.socialLinks.linkedin} target="_blank">
                          <Icon
                            color={colors.bg2}
                            icon="ph:linkedin-logo-duotone"
                            fontSize={24}
                          />
                        </a>
                      </div>
                    </div>
                    <Link to={`/team/${employee.id}`} className="info-box">
                      <h6>{employee.name}</h6>
                      <p>{employee.title}</p>
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default Team;
