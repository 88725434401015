import React from "react";
import { Col, Row, Tab } from "react-bootstrap";

import Heading from "../../../components/shared/Heading";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";

const CourseDetailsStructureTab = ({
  data,
  postTitle,
  title = "Programme Structure",
}) => {
  return (
    <Tab.Pane eventKey="Structure" className="instructor-tab">
      <Heading tag="h5" title={title} />
      <p className="course-postTitle mb-4">{postTitle}</p>
      {data?.map((module, index) => (
        <div className="instructor-item" key={index}>
          <Row>
            <Col md="12">
              <div className="instructor-content">
                <div className="instructor-box">
                  <div className="top-content d-flex justify-content-between flex-wrap">
                    <div className="instructor-name">
                      <h6>{module?.title}</h6>
                      <div className="d-flex flex-wrap">
                        {module?.courseType ? (
                          <div className="course-structure-details d-flex align-items-center">
                            <Icon
                              icon="ph:star-duotone"
                              fontSize={24}
                              color={colors.bg2}
                            />
                            <p className="mr-4 mb-0 ml-2">
                              {module?.courseType}
                            </p>
                          </div>
                        ) : null}
                        {module?.credits ? (
                          <div className="course-structure-details d-flex align-items-center">
                            <Icon
                              icon="ph:star-duotone"
                              fontSize={24}
                              color={colors.bg2}
                            />
                            <p className="mb-0 ml-2">
                              {module?.credits} credits
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="instructor-desk">
                    <ul className="list-unstyled">
                      {typeof module?.description === "string" ? (
                        <p>{module?.description}</p>
                      ) : (
                        <>
                          {module?.postTitle ? (
                            <li>
                              <p>
                                <strong>{module?.postTitle}</strong>
                              </p>
                            </li>
                          ) : null}
                          {module?.description?.map((desc, index) => (
                            <li key={index}>
                              <div>
                                <Icon
                                  icon="ph:paper-plane-right-duotone"
                                  fontSize={24}
                                  className="pt-1"
                                  color={colors.bg1}
                                />
                              </div>
                              <p>{desc}</p>
                            </li>
                          ))}
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </Tab.Pane>
  );
};

export default CourseDetailsStructureTab;
