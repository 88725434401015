import { uniqueId } from "lodash";
import React, { useMemo, useCallback } from "react";
import { useState } from "react";
import { createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from "slate-react";
import { useEventListener } from "../../../hooks/useEventListener";
import { EVENT_TOPICS } from "../../../utilities/eventUtils";

import RichTextElementRenderer from "./RichTextElementRenderer";
import RichTextLeafRenderer from "./RichTextLeafRenderer";

const RichTextHTMLParser = ({ text }) => {
  const [uuid, setUuid] = useState(uniqueId("prefix-"));
  const editor = useMemo(() => withHistory(withReact(createEditor())), [text]);

  const renderElement = useCallback(
    (props) => <RichTextElementRenderer {...props} style />,
    []
  );
  const renderLeaf = useCallback(
    (props) => <RichTextLeafRenderer {...props} />,
    []
  );

  useEventListener(EVENT_TOPICS.ON_LANGUAGE_CHANGED, () => {
    setUuid(uniqueId("prefix-"));
  });

  return (
    <Slate editor={editor} value={text} key={uuid}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly
      />
    </Slate>
  );
};

export default RichTextHTMLParser;
