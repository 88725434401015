import { Skeleton } from "@mui/material";
import React from "react";

const VerticalTopNewsCardSkeleton = () => {
  return (
    <div className="news-top-stories-panel-item">
      <Skeleton
        variant="rounded"
        animation="wave"
        className="news-top-stories-panel-item-img"
      />
      <div className="news-top-stories-panel-item-content">
        <Skeleton
          variant="rounded"
          animation="wave"
          className="news-top-stories-panel-item-title"
          height={70}
        />
        <div className="news-top-stories-panel-item-details">
          <Skeleton
            variant="rounded"
            animation="wave"
            className="news-top-stories-panel-item-date"
            height={20}
            width={95}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            className="news-top-stories-panel-item-category"
            height={20}
            width={95}
          />
        </div>
      </div>
    </div>
  );
};

export default VerticalTopNewsCardSkeleton;
