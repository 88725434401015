import { css } from "@emotion/react";
import React from "react";
import { useFocused, useSelected } from "slate-react";

const RichTextElementRenderer = ({ attributes, children, element }) => {
  const props = { attributes, children, element };
  const style = { textAlign: element.align };
  switch (element.type) {
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-one":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-two":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-three":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-four":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-five":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-six":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "link":
      return <LinkComponent {...props} />;
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

export const LinkComponent = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();

  return (
    <>
      <a
        {...attributes}
        href={element?.url}
        target="_blank"
        className={
          selected
            ? css`
                box-shadow: 0 0 0 3px #ddd;
              `
            : ""
        }
      >
        {children}
      </a>
      {selected && focused && (
        <div>
          <a href={element.url} rel="noreferrer" target="_blank">
            {element.url}
          </a>
        </div>
      )}
    </>
  );
};

export default RichTextElementRenderer;
