import React from "react";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements";

const JobDescriptionSection = ({ title, listItems, description }) => {
  return (
    <div className="course-learn">
      <h5>{title}</h5>
      {description && <p>{description}</p>}
      <ul className="list-unstyled">
        {listItems?.map((argument, index) => (
          <li className="d-flex mb-3 job" key={index}>
            <div>
              <Icon
                icon="ph:paper-plane-right-duotone"
                fontSize={24}
                color={colors.bg2}
              />
            </div>
            <p>{argument}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default JobDescriptionSection;
