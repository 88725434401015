import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BreadcrumbBox from "../../components/common/Breadcrumb";
import Heading from "../../components/shared/Heading";
import EnglishData from "../../data/wellbeing/wellbeing.json";
import RussianData from "../../data/wellbeing/wellbeing-ru.json";
import UzbekData from "../../data/wellbeing/wellbeing-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { Styles } from "./styles";
import { colors } from "../../components/common/element/elements";
import { Icon } from "@iconify/react";

const Wellbeing = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  return (
    <Styles>
      <div className="main-wrapper wellbeing-page">
        <BreadcrumbBox title="Wellbeing" image="/assets/images/wellbeing.jpg" />

        <section className="wellbeing-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="wellbeing-content">
                  <div className="py-5">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data?.section1?.title}
                    />
                    {data?.section1?.paragraphs?.map((desc, index) => (
                      <p className="pb-3" key={index}>
                        {desc}
                      </p>
                    ))}
                    <h6>{data?.section1?.offers.title}</h6>
                    <ul className="list-unstyled">
                      {data?.section1?.offers?.items?.map((item, index) => (
                        <li className="d-flex mb-3 item" key={index}>
                          <div>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={20}
                              color={colors.bg2}
                            />
                          </div>
                          <p>{item}</p>
                        </li>
                      ))}
                    </ul>
                    <h6>{data?.section1?.supports.title}</h6>
                    <ul className="list-unstyled">
                      {data?.section1?.supports?.items?.map((item, index) => (
                        <li className="d-flex mb-3 item" key={index}>
                          <div>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={20}
                              color={colors.bg2}
                            />
                          </div>
                          <p>{item}</p>
                        </li>
                      ))}
                    </ul>
                    <p>
                      <span>{data?.section1?.subtitle?.prelink}</span>
                      <a href={`mailto:${data?.section1?.subtitle?.link}`}>
                        {data?.section1?.subtitle?.link}
                      </a>
                      {data?.section1?.subtitle?.postlink}
                    </p>
                  </div>

                  <div className="pb-5">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data?.section2?.title}
                    />
                    {data?.section2?.paragraphs?.map((desc, index) => (
                      <p className="pb-3" key={index}>
                        {desc}
                      </p>
                    ))}
                    <p>
                      <span>{data?.section2?.subtitle?.prelink}</span>
                      <a href={`mailto:${data?.section2?.subtitle?.link}`}>
                        {data?.section2?.subtitle?.link}
                      </a>
                      {data?.section2?.subtitle?.postlink}
                    </p>
                  </div>

                  <div className="pb-5">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data?.section3?.title}
                    />
                    <p className="pb-3">{data?.section3?.subtitle1}</p>
                    <h6 className="pb-3">{data?.section3?.subtitle2}</h6>
                    <h6>{data?.section1?.offers.title}</h6>
                    <ul className="list-unstyled">
                      {data?.section3?.difficulties?.items?.map(
                        (item, index) => (
                          <li className="d-flex mb-3 item" key={index}>
                            <div>
                              <Icon
                                icon="ph:paper-plane-right-duotone"
                                fontSize={20}
                                color={colors.bg2}
                              />
                            </div>
                            <p>{item}</p>
                          </li>
                        )
                      )}
                    </ul>
                    <p className="pb-3">
                      <b>{data?.section3?.subtitle3.bold}</b>{" "}
                      <span>{data?.section3?.subtitle3.normal}</span>
                    </p>
                    <p>
                      <span>{data?.section3?.subtitle4?.prelink}</span>
                      <a href={`https://${data?.section3?.subtitle4?.link1}`}>
                        {data?.section3?.subtitle4?.link1}
                      </a>
                      <span>{data?.section3?.subtitle4?.postlink1}</span>
                      <a href={`mailto:${data?.section3?.subtitle4?.link2}`}>
                        {data?.section3?.subtitle4?.link2}
                      </a>
                      <span>{data?.section3?.subtitle4?.postlink2}</span>
                    </p>
                  </div>

                  <div className="pb-5">
                    <Heading
                      tag="h4"
                      className="strategy-title"
                      title={data?.section4?.title}
                    />
                    <h6>{data?.section4?.ptu?.title}</h6>
                    <ul className="list-unstyled">
                      {data?.section4?.ptu?.items?.map((item, index) => (
                        <li className="d-flex mb-3 item" key={index}>
                          <div>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={20}
                              color={colors.bg2}
                            />
                          </div>
                          <p>{item}</p>
                        </li>
                      ))}
                    </ul>
                    <h6>{data?.section4?.students?.title}</h6>
                    <ul className="list-unstyled">
                      {data?.section4?.students?.items?.map((item, index) => (
                        <li className="d-flex mb-3 item" key={index}>
                          <div>
                            <Icon
                              icon="ph:paper-plane-right-duotone"
                              fontSize={20}
                              color={colors.bg2}
                            />
                          </div>
                          <p>{item}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default Wellbeing;
