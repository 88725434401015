import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";
import { useTranslation } from "react-i18next";

export const AboutSection = ({ data }) => {
  const { t } = useTranslation();
  return (
    <section id="about" className="about">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <div className="title-wrapper">
                <Heading
                  tag="h4"
                  className="strategy-title"
                  title={data.title}
                />
              </div>
              {data.descriptions.map((desc, i) => (
                <p className="description" key={i}>
                  {desc}
                </p>
              ))}
              <div className="actions">
                <div className="apply-button">
                  <a
                    target="_blank"
                    className="apply"
                    href={
                      "https://drive.google.com/file/d/1alw7FIUi5_g6rJrGxjXKwfUX0hGH8dhH/view?usp=sharing"
                    }
                  >
                    {t("See Our Programme")}
                  </a>
                </div>
                <div className="apply-button">
                  <a
                    target="_blank"
                    className="apply"
                    href={
                      "https://forms.office.com/Pages/ResponsePage.aspx?id=X3aHJ0jy4kCTBf-9vu4HY1Fv0usvgehGu3HyRhs032JUN1Y1Q0xWMUNWSUdPVE45Ujk0RDVTT1M2QS4u"
                    }
                  >
                    {t("Attendance Form")}
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
