import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import zIndex from "@mui/material/styles/zIndex";
import React from "react";
import { useState } from "react";

const TooltipInformationComponent = ({ componentToShow }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  function handleMouseOut() {
    setIsHovering(false);
  }

  function handleMouseOver() {
    setIsHovering(true);
  }

  function handleOnClick() {
    setIsClicked((prev) => !prev);
  }

  return (
    <div
      className="info position-relative"
      onMouseOut={handleMouseOut}
      onMouseOver={handleMouseOver}
      onClick={handleOnClick}
      style={{ backgroundColor: isClicked ? "#662a30" : "" }}
    >
      <FontAwesomeIcon icon={solid("question")} color="white" />
      {isHovering || isClicked ? (
        <div
          className="position-absolute"
          style={{ right: 0, top: "30px", zIndex: 100, borderRadius: "8px" }}
        >
          {componentToShow}
        </div>
      ) : null}
    </div>
  );
};

export default TooltipInformationComponent;
