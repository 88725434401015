import React from "react";

import VerticalTopNewsCardSkeleton from "./VerticalTopNewsCardSkeleton";

const VerticalTopNewsListSkeleton = () => {
  return (
    <div>
      <VerticalTopNewsCardSkeleton />
      <VerticalTopNewsCardSkeleton />
      <VerticalTopNewsCardSkeleton />
      <VerticalTopNewsCardSkeleton />
      <VerticalTopNewsCardSkeleton />
      <VerticalTopNewsCardSkeleton />
    </div>
  );
};

export default VerticalTopNewsListSkeleton;
