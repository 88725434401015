import React, { useEffect } from "react";
import { Container, Row, Col, Tab } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { BreadcrumbBox } from "../../components/common/newBread";
import JobDescriptionSection from "./JobDescriptionSection";
import Button from "../../components/shared/Button";
import vacancyData from "../../data/vacancy/vacancy.json";

import { Styles } from "../courses/styles/course.js";

const JobDescription = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const vacancy = vacancyData.find((vacancy) => vacancy.id == id);

  useEffect(() => {
    const courseButton = document.querySelectorAll(".course-button");
    courseButton.forEach((button) => {
      button.addEventListener("click", () => {
        button.classList.toggle("active");
        const content = button.nextElementSibling;

        if (button.classList.contains("active")) {
          content.className = "course-content show";
          content.style.maxHeight = content.scrollHeight + "px";
        } else {
          content.className = "course-content";
          content.style.maxHeight = "0";
        }
      });
    });
  });

  return (
    <Styles>
      <div className="main-wrapper course-details-page">
        <BreadcrumbBox
          title="Job Description"
          image="/assets/images/banner-6.jpg"
        />
        <Styles>
          <section className="course-details-area">
            <Container>
              <Row>
                <Col lg="12" md="8" sm="12">
                  <div className="course-details-top">
                    <div className="heading">
                      <h4>{vacancy?.JobDescription}</h4>
                    </div>
                    <div className="course-top-overview">
                      <div className="d-flex overviews">
                        <div className="author">
                          <div className="author-name">
                            <h6>Job Title</h6>
                            <p>{vacancy?.jobTitle}</p>
                          </div>
                        </div>
                        <div className="category">
                          <h6>Reports To</h6>
                          <p>{vacancy?.reportsTo}</p>
                        </div>
                        <div className="category">
                          <h6>Term</h6>
                          <p>{vacancy?.term}</p>
                        </div>
                        <div className="price">
                          <h6>Deadline</h6>
                          <p>{vacancy?.deadline}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <h5 className="strategy-title">
                        {vacancy?.aboutUniversity?.title}
                      </h5>
                      {vacancy?.aboutUniversity?.description?.map((desc) => (
                        <p className="strategy-desc mb-2" key={desc}>
                          {desc}
                        </p>
                      ))}
                    </div>
                    <div className="course-tab-list">
                      <Tab.Container defaultActiveKey="overview">
                        <Tab.Content>
                          <Tab.Pane
                            eventKey="overview"
                            className="overview-tab"
                          >
                            {vacancy?.disciplines && (
                              <JobDescriptionSection
                                key={vacancy?.disciplines}
                                title={"Disciplines"}
                                listItems={vacancy?.disciplines?.arguments}
                                description={vacancy?.disciplines?.title}
                              />
                            )}
                            <JobDescriptionSection
                              title={vacancy?.duties?.title}
                              listItems={vacancy?.duties?.arguments}
                            />
                            <JobDescriptionSection
                              title={"Qualification"}
                              listItems={vacancy?.qualification}
                            />
                            <JobDescriptionSection
                              title={"Experience"}
                              listItems={vacancy?.experience}
                            />
                            <JobDescriptionSection
                              title={"Skills and Abilities"}
                              listItems={vacancy?.skills}
                            />
                            <JobDescriptionSection
                              title={
                                vacancy?.applicationProcess?.title &&
                                "Application Process"
                              }
                              description={vacancy?.applicationProcess?.title}
                              listItems={vacancy?.applicationProcess?.arguments}
                            />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      text="Apply Now"
                      onClick={() =>
                        navigate(`/employees-apply-form/${vacancy.id}`)
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Styles>
      </div>
    </Styles>
  );
};

export default JobDescription;
