import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

export const ThematicAreasSection = ({ data }) => {
  return (
    <section className="thematic" id="thematic">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Thematic Areas"}
              />
              <div className="topic-list">
                {data.thematic_areas.map((area, i) => (
                  <div className="topic" key={i}>
                    {area}
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
