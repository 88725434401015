import React from "react";

import "./ToastComponent.css";

const ToastComponent = ({ title, description }) => {
  return (
    <div className="ToastComponent">
      <h6 className="toast-title">{title}</h6>
      <p>{description}</p>
    </div>
  );
};

export default ToastComponent;
