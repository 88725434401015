import React from "react";
import Button from "../shared/Button.jsx";

import { Styles } from "./styles/vacancyItem.js";

const VacancyItem = ({ title, buttonsToRender, deadline }) => (
  <Styles>
    <div className="vacancy-item">
      <h6 className="vacancy-title">{title}</h6>
      <div className="apply-button-wrapper">
        <h6 className="deadline">{deadline ? deadline : ""}</h6>
        <div className="apply-buttons">
          {buttonsToRender?.map((button) => (
            <Button text={button?.title} onClick={button?.onClick} />
          ))}
        </div>
      </div>
    </div>
  </Styles>
);

export default VacancyItem;
