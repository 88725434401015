import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .team-details-page {
    margin-bottom: 70px;

    .main-details {
      display: flex;
      align-items: center;
      column-gap: 30px;
      flex-wrap: wrap;

      .image {
        max-width: 400px;
        margin-right: 20px;

        img {
          width: 100%;
          border-radius: 30px;
        }
      }

      .employee-details {
        .employee-name {
          font-weight: 600;
          margin-bottom: 5px;
          color: ${colors.black1};
        }

        .employee-title {
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 15px;
        }

        .employee-contact {
          color: ${colors.black1};
          font-weight: 400;

          &:hover {
            color: ${colors.bg1};
          }
        }

        .employee-research {
          margin-bottom: 15px;
          color: ${colors.text2};
          font-weight: 500;
          strong {
            color: ${colors.black1};
          }
        }

        .employee-social-media {
          display: flex;
          list-style: none;
          column-gap: 10px;
          margin-top: 20px;
          margin-bottom: 1px;

          .employee-social-media-link {
            padding: 10px 17px;
            border-radius: 50%;
            background-color: ${colors.bg1};

            i {
              color: white;
            }
          }

          .linkedin {
            padding: 10px 14px;
          }
        }
      }
    }

    .accordion-item {
      border-radius: 8px;
      box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
      padding: 20px 25px;
    }

    .accordion-title {
      color: ${colors.black1};
      font-weight: 700 !important;
      transition: width 0.3s ease;
      position: relative;
      padding-bottom: 0;
      margin-bottom: 0;
      cursor: pointer;
      padding-right: 15px;

      &::before {
        position: absolute;
        content: "";
        background: rgb(102, 42, 48);
        width: 0px;
        height: 2px;
        bottom: -20px;
        left: 0;
        transition: 0.3s linear;
      }

      h4 {
        color: #444444;
        font-weight: 600;
      }
    }

    .accordion-open {
      width: 100%;
      margin-bottom: 20px;

      &::before {
        position: absolute;
        content: "";
        background: rgb(102, 42, 48);
        width: 100%;
        height: 2px;
        bottom: -20px;
        left: 0;
        transition: 0.3s linear;
      }
    }

    .accordion-icon {
      transition: transform 0.3s ease;
    }

    .icon-rotate {
      transform: rotate(90deg);
    }

    .education {
      position: relative;
      margin-top: 40px;

      .education-duration {
        position: absolute;
        top: -20px;
        left: 0px;
        z-index: 10;
        border-radius: 8px;
        box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
        padding: 10px 15px;
        font-size: 20px;
        font-weight: 500;
        color: ${colors.bg1};
        background-color: white;
      }

      .education-description {
        margin-left: 30px;
        border-radius: 8px;
        box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
        padding: 30px 15px 10px;
        font-weight: 500;
      }
    }

    .publication {
      margin-top: 15px;

      &-text {
        p {
          border-radius: 8px;
          box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
          padding: 10px 15px;
        }
      }

      &-link {
        padding-left: 0px !important;

        @media (max-width: 768px) {
          margin-top: 15px;
          height: 47px;
          margin-left: 16px;
        }

        a {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 8px;
          box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
          color: ${colors.bg1};

          &:hover {
            background-color: ${colors.bg1};
            color: white;
          }
        }
      }
    }
  }
`;
