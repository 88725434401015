import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

export const WorkshopsSection = ({ data }) => {
  return (
    <section className="workshops" id="workshops">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Workshops"}
              />
              <div className="committee-list">
                {data.workshops.map((workshop, i) => (
                  <div className="committee" key={i}>
                    <img
                      src={`/assets/images/${workshop?.img}`}
                      alt="Member avatar"
                      className="avatar"
                    />
                    <div className="info">
                      <div className="name">{workshop.theme}</div>
                      <div className="job">{workshop.name}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
