import { bannerDisabledPaths } from "../../configs/appConfig";

const DisplayHOC = ({ children }) => {
  const currentPath = window.location.pathname;

  if (!bannerDisabledPaths.includes(currentPath)) return children;

  return null;
};

export default DisplayHOC;
