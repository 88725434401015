import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const MobileMenuNavigationitem = ({ link, title, onClick, className }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-menu-item">
        <button className={`mb-menu-button ${className}`}>
          <Link to={link} onClick={onClick}>
            {t(title)}
          </Link>
        </button>
      </div>
    </>
  );
};

export default MobileMenuNavigationitem;
