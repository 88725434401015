import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const NavigationLink = ({
  pathName,
  iconName = "las la-angle-right",
  title,
}) => {
  const { t } = useTranslation();

  return (
    <li>
      <Link to={pathName}>
        <i className={iconName}></i>
        {t(title)}
      </Link>
    </li>
  );
};

export default NavigationLink;
