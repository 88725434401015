import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

// Plenary Speakers
// Keynote Speakers

export const SpeakersSection = ({ data }) => {
  return (
    <section className="speakers" id="speakers">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Plenary Speakers"}
              />
              <div className="committee-list">
                {data.plenary_speakers.map((member, i) => (
                  <div className="committee" key={i}>
                    <img
                      src={member?.img}
                      alt="Member avatar"
                      className="avatar"
                    />
                    <div className="info">
                      <div className="name">{member.name}</div>
                      <div className="job">{member.job}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="main" id="keynote_speakers">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Keynote Speakers"}
              />
              <div className="committee-list">
                {data.keynote_speakers.map((member, i) => (
                  <div className="committee" key={i}>
                    <img
                      src={member?.img}
                      alt="Member avatar"
                      className="avatar"
                    />
                    <div className="info">
                      <div className="name">{member.name}</div>
                      <div className="job">{member.job}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
