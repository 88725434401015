export const ENVIRONMENTS_ENUMS = {
  LOCAL: "local",
  PROD: "prod",
};

// TODO: If there is any dev, stg and prod url rewrite this using that links
const envPatterns = {
  PROD: /.*pharmatechuni.uz/g,
  PROD_PHTU: /.*phtu.uz/g,
};

export const getCurrentEnvironment = () => {
  const currentHost = window.location.host.toLowerCase();

  if (
    envPatterns.PROD.test(currentHost) ||
    envPatterns.PROD_PHTU.test(currentHost)
  ) {
    return ENVIRONMENTS_ENUMS.PROD;
  }

  return ENVIRONMENTS_ENUMS.LOCAL;
};
