import backendHttpRequest from "../../repositories/backendHttpRequest";
import { config } from "../../configs/repositoryConfig";

import { convertToQueryString } from "../../utilities/httpUtils";

export const getCategories = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getCategories,
  };

  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${
    queryString ? `?${queryString}` : ""
  }`;

  const apiCategories = await backendHttpRequest({
    resourceObject,
  });

  return apiCategories;
};
