import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import EnglishData from "../../data/partner/partner.json";
import RussianData from "../../data/partner/partner-rus.json";
import UzbekData from "../../data/partner/partner-uz.json";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";

import { useLanguageDetector } from "../../hooks/useLanguageDetector";

import "./Partners.css";

const Partners = () => {
  const [partnersData] = useLanguageDetector(
    EnglishData,
    RussianData,
    UzbekData
  );

  return (
    <div className="main-wrapper careers-page">
      <BreadcrumbBox title="Our Partners" image="/assets/images/banner-3.jpg" />
      <section className="strategy-area">
        <Container>
          <Row>
            <Col md="12">
              <div>
                <div>
                  {partnersData?.description?.map((desc) => (
                    <p key={desc} className="strategy-desc mb-3">
                      {desc}
                    </p>
                  ))}
                </div>
                {partnersData?.dataList?.map((partner) => (
                  <div key={partner?.id} className="mb-5 strategy-desc-wrapper">
                    <h6 className="strategy-years mb-3">
                      {partner?.partnerName}
                    </h6>
                    <div>
                      {partner?.description?.map((desc, index) => (
                        <p
                          className={`strategy-desc ${
                            partner?.description.length - 1 === index
                              ? "d-contents"
                              : ""
                          } mb-2`}
                          key={index}
                        >
                          {desc}
                        </p>
                      ))}
                      <a href={partner?.partnerLink} className="partner-link">
                        {partner?.partnerLink}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Partners;
