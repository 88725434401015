import styled from "styled-components";
import { colors } from "../../components/common/element/elements";

export const Styles = styled.div`
  .students-union-area {
    .students-union-content {
      padding: 32px 0;

      .par {
        font-size: 16px;
        line-height: 28px;
        color: #666;
        padding-bottom: 16px;
      }

      ul {
        padding: 24px 0;
        li {
          display: flex;
          align-items: center;
          column-gap: 10px;
          border-radius: 8px;
          box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
          padding: 10px 15px;
          font-size: 15px;
          color: ${colors.text3};
          line-height: 25px;
          margin-bottom: 15px;
        }

        li.item {
          i {
            color: ${colors.bg1};
            font-size: 20px;
          }
        }
      }
    }
  }

  .section {
    padding-bottom: 20px;
  }
`;
