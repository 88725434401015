import React from "react";
import { Styles } from "./styles/conference.js";
import IconBox from "../../components/IconBox.jsx";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "../../data/conference/conference-en.json";
import RussianData from "../../data/conference/conference-rus.json";
import UzbekData from "../../data/conference/conference-uz.json";
import IconBoxData from "./data/iconbox.json";
import IconBoxDataRu from "./data/iconbox-ru.json";
import IconBoxDataUz from "./data/iconbox-uz.json";
import { AbstractSection } from "./sections/Deadline/index.jsx";
import { AboutSection } from "./sections/About";
import { BreadcrumbSection } from "./sections/Breadcrumb/index.jsx";
import { ThematicAreasSection } from "./sections/ThematicAreas";
import { CommitteeSection } from "./sections/Committee";
import { SpecialIssueSection } from "./sections/SpecialIssue/index.jsx";
import { InstructionSection } from "./sections/Instruction/index.jsx";
import { ProgrammeSection } from "./sections/Programme/index.jsx";
import { SpeakersSection } from "./sections/Speakers/index.jsx";
import { SponsorsSection } from "./sections/Sponsors/index.jsx";
import { OnlineLinksSection } from "./sections/OnlineLinks/index.jsx";
import { WorkshopsSection } from "./sections/Workshops/index.jsx";

const Conference = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const [iconboxData] = useLanguageDetector(
    IconBoxData,
    IconBoxDataRu,
    IconBoxDataUz
  );

  return (
    <Styles>
      <div className="main-wrapper conference-page">
        <div id="top">
          <BreadcrumbSection />
        </div>

        <IconBox data={iconboxData} />

        <AboutSection data={data} />

        <OnlineLinksSection />

        <ThematicAreasSection data={data} />

        <AbstractSection data={data} />

        <SpecialIssueSection data={data} />

        <InstructionSection data={data} />

        <CommitteeSection data={data} />

        <SpeakersSection data={data} />

        <WorkshopsSection data={data} />

        <ProgrammeSection data={data} />

        <SponsorsSection data={data} />
      </div>
    </Styles>
  );
};

export default Conference;
