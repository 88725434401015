import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Styles } from "./styles/announcementBar.js";

const AnnouncementBar = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Styles>
      <div className="announcement-header">
        <Container className="py-2">
          <div className="announcement-text">
            <p className="mr-2">{t(data.title)}</p>
            <a href={data.linkHref}>{t(data.linkText)}</a>
          </div>
        </Container>
      </div>
    </Styles>
  );
};

export default AnnouncementBar;
