import { Skeleton } from "@mui/material";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Fetcher from "../../../components/shared/Fetcher";
import Heading from "../../../components/shared/Heading";
import { useCurrentLanguage } from "../../../hooks/useCurrentLanguage";
import { useQueryParamByName } from "../../../hooks/useQueryParamByName";
import { IMAGE_KEYS } from "../../../utilities/constants";
import { unixDateConvertor } from "../../../utilities/dateTimeUtils";
import { useNewsPageContext } from "../../hooks/useNewsPageContext";
import newsService from "../../service/newsService";
import VerticalTopNewsCard from "../reusableComponents/VerticalTopNewsCard";
import VerticalTopNewsListSkeleton from "../reusableComponents/skeletons/VerticalTopNewsListSkeleton";

const VerticalTopNewsBlock = () => {
  const navigate = useNavigate();
  const [language] = useCurrentLanguage();
  const { newsState, newsDispatch } = useNewsPageContext();

  const currentPageQueryString = useQueryParamByName("current-page");
  const pageSizeQueryString = useQueryParamByName("page-size");

  const fetchTopNews = useCallback(
    async () =>
      await newsService.getNews({
        isAtTop: true,
        currentPage: currentPageQueryString,
        pageSize: pageSizeQueryString,
      }),
    [currentPageQueryString, pageSizeQueryString]
  );

  const handleOnLoadFetchedTopNews = useCallback(
    (response) => {
      newsDispatch({ type: "topNewsFetch", response: response });
    },
    [newsDispatch]
  );

  return (
    <div className="news-side-panel-wrapper top-stories-wrapper">
      <Heading tag="h5" className="news-side-panel-title" title="Top Stories" />
      <Fetcher
        action={fetchTopNews}
        onLoad={handleOnLoadFetchedTopNews}
        loadingComponent={<VerticalTopNewsListSkeleton />}
      >
        {newsState?.topNews?.map((news) => (
          <VerticalTopNewsCard
            key={news?.id}
            image={news?.imageUrls?.[language]?.[IMAGE_KEYS.COVER_IMAGE_GET]}
            title={news?.title?.[language]}
            date={unixDateConvertor(news?.createdAt)}
            categories={news?.categoryIds}
            onClick={() => navigate(`/news/${news?.id}`)}
            language={language}
          />
        ))}
      </Fetcher>
    </div>
  );
};

export default VerticalTopNewsBlock;
