import { unionBy } from "lodash";
import React, { useReducer } from "react";

export const NewsPageContext = React.createContext();

const initialNewsState = {
  totalRecords: 0,
  currentPage: 1,
  pageSize: 0,
  totalPages: 0,
  data: [],
  categories: [],
};

function newsReducer(state, action) {
  const responseData = action?.response?.data;

  const updatedNews = responseData?.map((news) => {
    const categories = news?.categoryIds.map((category) => {
      return {
        id: category,
        title: state?.categories?.find((item) => item?.id === category)?.title,
      };
    });

    return { ...news, categoryIds: categories };
  });

  switch (action.type) {
    case "latestNewsFetch":
      return {
        ...state,
        latestNews: unionBy(state.latestNews, updatedNews, "id"),
      };

    case "topNewsFetch":
      return {
        ...state,
        topNews: unionBy(state.topNews, updatedNews, "id"),
      };

    case "allNewsFetch":
      return {
        ...state,
        ...action.response,
        data: unionBy(state.data, updatedNews, "id"),
      };

    case "categoriesFetch":
      return {
        ...state,
        categories: action.response,
      };

    default:
      return initialNewsState;
  }
}

function NewsPageContextProvider({ children }) {
  const [newsState, newsDispatch] = useReducer(newsReducer, initialNewsState);

  return (
    <NewsPageContext.Provider
      value={{
        newsState,
        newsDispatch,
      }}
    >
      {children}
    </NewsPageContext.Provider>
  );
}

export default NewsPageContextProvider;
