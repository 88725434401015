import React from "react";
import { motion } from "framer-motion";

const VerticalTopNewsCard = ({
  image,
  title,
  categories,
  date,
  onClick,
  language,
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="news-top-stories-panel-item"
      onClick={onClick}
    >
      <div
        className="news-top-stories-panel-item-img"
        style={{
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div className="news-top-stories-panel-item-content">
        <div className="news-top-stories-panel-item-title">{title}</div>
        <div className="news-top-stories-panel-item-details">
          <div className="news-top-stories-panel-item-date">
            <i className="las la-calendar" />
            <p>{date}</p>
          </div>
          <div className="news-top-stories-panel-item-category">
            {categories?.[0]?.title?.[language]}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default VerticalTopNewsCard;
