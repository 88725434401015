import React from "react";
import { useTranslation } from "react-i18next";
import { Nav, Tab } from "react-bootstrap";

const TabbarComponent = ({ tabTitles, tabContent, defaultActiveKey }) => {
  const { t } = useTranslation();

  return (
    <Tab.Container defaultActiveKey={defaultActiveKey}>
      <Nav className="flex-column">
        {tabTitles?.map((title) => (
          <Nav.Item key={title}>
            <Nav.Link eventKey={title}>{t(title)}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>{tabContent}</Tab.Content>
    </Tab.Container>
  );
};

export default TabbarComponent;
