import React from "react";
import { Tab } from "react-bootstrap";
import { motion } from "framer-motion";

import Heading from "../../../components/shared/Heading";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";

const CouresDetailsOverviewTab = ({ data }) => {
  return (
    <Tab.Pane eventKey="Overview" className="overview-tab">
      <div className="course-desc">
        <Heading tag="h5" title={"Programme Description"} />
        <p>{data?.courseDescription}</p>
      </div>
      <div className="course-learn">
        <Heading tag="h5" title={"Learning Outcomes"} />
        <p>{data?.learningOutcomes?.description}</p>
        <ul className="list-unstyled learning-outcomes-list">
          {data?.learningOutcomes?.arguments?.map((argument) => (
            <motion.li key={argument} whileHover={{ scale: 1.02 }}>
              <div className="course-details-icon-wrapper">
                <Icon
                  icon="ph:check-circle-duotone"
                  fontSize={40}
                  color={colors.bg2}
                />
              </div>
              <p className="pl-1">{argument}</p>
            </motion.li>
          ))}
        </ul>
      </div>
    </Tab.Pane>
  );
};

export default CouresDetailsOverviewTab;
