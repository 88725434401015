import { Icon } from "@iconify/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { colors } from "../common/element/elements";

const ListItemWithIconAndValue = ({ title, iconName, value }) => {
  const { t } = useTranslation();
  return (
    <li>
      <Icon
        icon={iconName}
        fontSize={26}
        color={colors.bg1}
        className="mr-2 pt-1"
      />
      <p>
        <strong>{t(title)}: </strong>
        <span>{value}</span>
      </p>
    </li>
  );
};

export default ListItemWithIconAndValue;
