import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const BreadcrumbSection = () => {
  return (
    <section
      className="breadcrumb"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/event-6.jpg)`,
      }}
    >
      <Container>
        <Row>
          <Col md="12">
            <div className="breadcrumb-box">
              <h2 className="breadcrumb-title">
                PTU Inaugural International Conference on Pharmaceutics and Life
                Sciences
              </h2>
              <h2 className="year">ICPL 2024</h2>
              <p className="device">
                Frontiers of research and higher education
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
