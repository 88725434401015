import React from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { colors } from "../common/element/elements";

import Button from "./Button";

import "./FileDropDownComponent.css";
import IconButton from "./IconButton";

const FileDropDownComponent = ({
  text = "Drag and drop the files here, or click to select files",
  files,
  setFiles,
  multiple,
  buttonText = "Select File",
}) => {
  const { t } = useTranslation();

  const handleDropChange = (acceptedFiles, docs, setDocs) => {
    if (multiple) {
      return setDocs([
        ...docs,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    }

    return setDocs(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  return (
    <Dropzone
      onDrop={(acceptedFiles) =>
        handleDropChange(acceptedFiles, files, setFiles)
      }
      multiple={multiple}
    >
      {({ getRootProps, getInputProps }) => (
        <section className="position-relative">
          <div
            className="dropzone upload-zone small bg-white my-2 dz-clickable"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {files?.length < 1 || multiple ? (
              <>
                <p className="mb-3">{t(text)}</p>
                <Button
                  text={buttonText}
                  style={{ height: "38px" }}
                  className={"py-1"}
                />
              </>
            ) : null}
            {files?.map((file) => (
              <div
                key={file.name}
                className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
              >
                <div className="dz-image">
                  <img
                    src={file.preview}
                    alt="preview"
                    className="dropzone-image"
                  />
                </div>
              </div>
            ))}
          </div>
          {files?.length > 0 ? (
            <div className="delete-button-wrapper">
              <IconButton
                onClick={() => setFiles([])}
                iconName={"trash-alt"}
                color={colors.red}
              />
            </div>
          ) : null}
        </section>
      )}
    </Dropzone>
  );
};

export default FileDropDownComponent;
