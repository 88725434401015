import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Styles } from "./styles/conference.js";
import IconBox from "../../components/IconBox.jsx";
import Heading from "../../components/shared/Heading";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "../../data/conference/conference-en.json";
import RussianData from "../../data/conference/conference-rus.json";
import UzbekData from "../../data/conference/conference-uz.json";
import IconBoxData from "./data/iconbox.json";
import IconBoxDataRu from "./data/iconbox-ru.json";
import IconBoxDataUz from "./data/iconbox-uz.json";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements.js";

const CallForAbstract = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const [iconboxData] = useLanguageDetector(
    IconBoxData,
    IconBoxDataRu,
    IconBoxDataUz
  );
  return (
    <Styles>
      <div className="main-wrapper conference-page">
        <section
          className="breadcrumb"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/event-6.jpg)`,
          }}
        >
          <Container>
            <Row>
              <Col md="12">
                <div className="breadcrumb-box">
                  <h2 className="breadcrumb-title">
                    PTU Inaugural International Conference on Pharmaceutics and
                    Life Sciences
                  </h2>
                  <h2 className="year">ICPL 2024</h2>
                  <p className="device">
                    Frontiers of research and higher education
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <IconBox data={iconboxData} />

        <section className="alert">
          <Container>
            <Row>
              <Col md="12">
                <div className="main">
                  <img
                    src="/assets/images/journal-cover.jpg"
                    alt="announcement"
                  />
                  <div className="announcement">
                    <div className="announcement-info">
                      {data.announcement_info}
                      <div className="post-title">
                        <strong>{data.post_title}</strong>
                      </div>
                      <a
                        target="_blank"
                        href="https://think.taylorandfrancis.com/special_issues/applied-spectroscopy-reviews-pharmaceutical/?utm_source=TFO&utm_medium=cms&utm_campaign=JPG15743&_gl=1*1l52r29*_ga*MTg2OTA2MzgyMC4xNjc5MDY1NTY4*_ga_0HYE8YG0M6*MTcwOTk5OTY4My4xNC4wLjE3MDk5OTk2ODcuMC4wLjA.&_ga=2.147718581.362458905.1709999682-1869063820.1679065568"
                      >
                        {data.link}
                      </a>
                    </div>

                    <div className="deadline-list">
                      <div className="deadline">
                        <Icon
                          icon="ph:paper-plane-right-duotone"
                          fontSize={24}
                          color={colors.bg2}
                        />
                        <div>
                          {data.deadline_abstract.title}{" "}
                          {data.deadline_abstract.sample ? (
                            <>
                              (
                              <a
                                href={data.deadline_abstract.sample}
                                target="_blank"
                                className="sample"
                              >
                                {data.deadline_abstract.subtitle}
                              </a>
                              )
                            </>
                          ) : null}{" "}
                          : <strong>{data.deadline_abstract.deadline}</strong>
                        </div>
                      </div>

                      <div className="deadline">
                        <Icon
                          icon="ph:paper-plane-right-duotone"
                          fontSize={24}
                          color={colors.bg2}
                        />
                        <div>
                          {data.deadline_acceptance.title}:{" "}
                          <strong> {data.deadline_acceptance.deadline}</strong>
                        </div>
                      </div>
                      <div className="deadline">
                        <Icon
                          icon="ph:paper-plane-right-duotone"
                          fontSize={24}
                          color={colors.bg2}
                        />
                        <div>
                          {data.deadline_full_paper.title}:{" "}
                          <strong> {data.deadline_full_paper.deadline}</strong>
                        </div>
                      </div>
                      <div className="apply-button">
                        <a
                          target="_blank"
                          className="apply"
                          href="https://docs.google.com/forms/d/e/1FAIpQLScvQS4-Bzoz-VCXINvV-KXEfCUgQLxXWaBVBuR2NmqhCIjAFw/viewform"
                        >
                          {data.apply}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default CallForAbstract;
