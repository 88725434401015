import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEventListener } from "../hooks/useEventListener";
import { EVENT_TOPICS } from "../utilities/eventUtils";

export const useLanguageDetector = (EnglishData, RussianData, UzbekData) => {
  const { i18n } = useTranslation();
  const [data, setData] = useState(EnglishData);

  const languageDatas = {
    ru: RussianData,
    uz: UzbekData,
    en: EnglishData,
  };

  useEventListener(EVENT_TOPICS.ON_LANGUAGE_CHANGED, ({ language }) => {
    setData(languageDatas[language]);
  });

  useEffect(() => {
    setData(languageDatas[i18n?.language]);
  }, [i18n?.language]);

  return [data];
};
