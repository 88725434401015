import { useEffect } from "react";
import EventEmitter from "../utilities/eventUtils";

export const useEventListener = (
  eventName,
  eventHandler,
  dependencies = []
) => {
  useEffect(() => {
    const listener = EventEmitter.addListener(eventName, eventHandler);

    return () => {
      listener.remove();
    };
  }, [eventName, eventHandler, ...dependencies]);
};
