const convertValuesToArray = (obj = {}) => {
  return Object.values(obj);
};

const convertObjectToArray = (obj = {}) => {
  return Object.entries(obj);
};

const getObjectPropertyName = (obj, value) => {
  const index = Object.values(obj).findIndex((element) => element === value);

  return Object.keys(obj)[index];
};

const cleanUpObject = (obj) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

export {
  convertValuesToArray,
  convertObjectToArray,
  getObjectPropertyName,
  cleanUpObject,
};
