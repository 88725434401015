import styled from "styled-components";
import { colors } from "../../components/common/element/elements.js";

export const Styles = styled.div`
  .blog-details-page {
    .blog-details-area {
      padding: 70px 0;
      .blog-details-box {
        .blog-details-banner {
          img {
            width: 100%;
            max-height: 700px;
            border-radius: 8px;
            margin-bottom: 20px;
          }
        }

        .heading {
          h4 {
            color: ${colors.black1};
            font-weight: 600;
            line-height: 35px;
            margin-bottom: 10px;

            @media (max-width: 575px) {
              font-size: 20px;
            }
          }
        }

        .news-list-preheader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .news-list-details-wrapper {
            display: flex;
            align-items: center;
            column-gap: 20px;

            .news-list-news-date {
              display: flex;
              align-items: center;
              column-gap: 5px;

              i {
                font-size: 22px;
                color: ${colors.bg1};
              }

              p {
                font-size: 17px;
                font-weight: 500;
                color: ${colors.black1};
              }
            }
            .news-list-news-category-wrapper {
              display: flex;
              column-gap: 5px;
              a.news-list-news-category {
                display: flex;
                align-items: center;
                box-shadow: 0 8px 20px 5px rgb(0 0 0 / 7%);
                border-radius: 8px;
                color: ${colors.bg1};
                font-size: 14px;
                font-weight: 600;
                padding: 5px 10px;
                cursor: pointer;

                :hover {
                  color: ${colors.bg2};
                }
              }
            }

            .news-list-news-visitors {
              display: flex;
              align-items: center;
              column-gap: 5px;
            }

            p {
              font-size: 17px;
              font-weight: 500;
              color: ${colors.black1};
            }

            @media (max-width: 767px) {
              /* flex-direction: column;
              align-items: flex-start;
              row-gap: 15px; */
            }
          }

          @media (max-width: 767px) {
            flex-direction: column;
            align-items: start;
            row-gap: 15px;
          }
        }

        .blog-details-desc {
          font-size: 17px;
          margin-bottom: 45px;
          ul {
            margin-left: 30px;
            li {
              span {
                font-size: 15px;
                font-weight: 500;
                color: ${colors.black2};
              }
            }
          }

          p {
            margin-bottom: 10px;
            font-size: 15px;
            color: ${colors.text2};
            line-height: 25px;
            span {
              color: ${colors.black2};
              font-weight: 500;
              margin: 35px 0;
              position: relative;
            }

            a {
              span {
                text-decoration: underline;
                text-decoration-color: ${colors.bg1};
                color: ${colors.bg1};
              }
            }
          }
          ul {
            margin-top: 10px;
            li {
              font-size: 14px;
              color: ${colors.text3};
              line-height: 25px;
              margin-bottom: 15px;
              i {
                float: left;
                color: ${colors.green};
                border: 1px solid ${colors.border3};
                width: 35px;
                height: 35px;
                border-radius: 50%;
                text-align: center;
                padding-top: 9px;
                margin-top: 8px;
                margin-right: 15px;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .blog-comments {
          margin-bottom: 45px;
          h5 {
            color: ${colors.black2};
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 35px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              background: ${colors.green};
              width: 50px;
              height: 2px;
              bottom: 0;
              left: 0;
            }
          }
          .comment-box {
            border-bottom: 1px solid ${colors.border1};
            padding-bottom: 20px;
            margin-bottom: 25px;
            .comment-image {
              img {
                max-width: 80px;
                border-radius: 8px;
                margin-right: 20px;
              }
            }
            .comment-content {
              .content-title {
                .comment-writer {
                  h6 {
                    color: ${colors.black2};
                    font-weight: 600;
                    margin-bottom: 10px;
                  }
                  p {
                    font-size: 12px;
                    color: ${colors.text3};
                    margin-bottom: 5px;
                  }
                }
                .reply-btn {
                  button {
                    font-size: 14px;
                    color: ${colors.green};
                    background: transparent;
                    border: 1px solid ${colors.border3};
                    font-weight: 500;
                    border-radius: 25px;
                    padding: 4px 12px 3px;
                    margin-top: 3px;
                    i {
                      font-size: 17px;
                      vertical-align: text-top;
                    }
                    &:hover {
                      color: #ffffff;
                      background: ${colors.gr_bg};
                      border-color: ${colors.green};
                    }
                  }
                }
              }
              .comment-desc {
                p {
                  font-size: 14px;
                  color: ${colors.text2};
                  line-height: 25px;
                }
              }
            }
            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 0;
            }
          }
        }

        .blog-comment-form {
          form {
            @media (max-width: 575px) {
              margin-bottom: 30px;
            }
          }
        }
      }

      @media (max-width: 767px) {
        padding: 20px 0;
      }

      @media (max-width: 575px) {
        padding-bottom: 0;
      }
    }

    h4.news-title {
      color: #444444;
      font-weight: 600;
      padding-bottom: 10px;
      margin-bottom: 20px;
      position: relative;

      :before {
        position: absolute;
        content: "";
        background: #662a30;
        width: 50px;
        height: 2px;
        bottom: 0;
        left: 0;

        @media (max-width: 767px) {
          width: 30px;
        }
      }

      @media (max-width: 767px) {
        font-size: 16px;
        padding-bottom: 7.5px;
      }
    }

    .top-story-section {
      .top-story-container {
        display: flex;
        column-gap: 20px;

        .top-story {
          display: flex;
          flex-direction: column;
          row-gap: 10px;
          width: 300px;
          cursor: pointer;
          transition: 150ms;

          .top-stories-image {
            width: 100%;
            height: 170px;
            border-radius: 8px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }

          h6 {
            font-size: 13px;
            color: ${colors.bg1};
          }

          article {
            font-weight: 500;
            font-size: 14px;
            color: ${colors.black1};
          }
        }
      }

      @media (max-width: 767px) {
        margin: 30px 0;
      }
    }
  }
`;
