import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

// Conference Chairs
// Organizing Committee
// Conference Secretariat

export const CommitteeSection = ({ data }) => {
  return (
    <section className="committee" id="comittee">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Conference Chairs"}
              />
              <div className="committee-list">
                {data.conference_chairs.map((member, i) => (
                  <div className="committee" key={i}>
                    <img
                      src={member?.img}
                      alt="Member avatar"
                      className="avatar"
                    />
                    <div className="info">
                      <div className="name">{member.name}</div>
                      <div className="job">{member.job}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Organising Committee"}
              />
              <div className="committee-list">
                {data.committe_members.map((member, i) => (
                  <div className="committee" key={i}>
                    <img
                      src={member?.img}
                      alt="Member avatar"
                      className="avatar"
                    />
                    <div className="info">
                      <div className="name">{member.name}</div>
                      <div className="job">{member.job}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Conference Secretariat"}
              />
              <div className="committee-list">
                {data.conference_secretariat.map((member, i) => (
                  <div className="committee" key={i}>
                    <img
                      src={member?.img}
                      alt="Member avatar"
                      className="avatar"
                    />
                    <div className="info">
                      <div className="name">{member.name}</div>
                      <div className="job">{member.job}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
