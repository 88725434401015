import React from "react";
import { Link } from "react-router-dom";

import Heading from "../../../components/shared/Heading";

import { useLanguageDetector } from "../../../hooks/useLanguageDetector";

import { Styles } from "../styles/popularCourse.js";

import EnglishData from "../../../data/course/filter.json";
import RussianData from "../../../data/course/filter-rus.json";
import UzbekData from "../../../data/course/filter-uz.json";

const PopularCourse = () => {
  const [courseData] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <Styles>
      <div className="popular-course">
        <Heading tag="h5" title="Popular Programmes" />
        <div className="popular-items">
          {courseData?.dataList?.map((data, i) => (
            <Link
              to={{ pathname: `/programme-details/${data.id}`, state: data }}
              className="item-box"
              key={i}
            >
              <div className="item-img">
                <img src={`/assets/images/${data.imgUrl}`} alt="" />
              </div>
              <div className="item-content">
                <p className="title">{data.courseTitle}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </Styles>
  );
};

export default PopularCourse;
