import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  .contact-page {
    .contact-area {
      padding: 68px 0 0;
      .contact-box-title {
        h4 {
          color: ${colors.black1};
          font-weight: 600;
          padding-bottom: 10px;
          margin-bottom: 30px;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            background: ${colors.bg1};
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
          }

          @media (max-width: 575px) {
            font-size: 20px;
          }
        }
      }

      .contact-icon-box {
        display: flex;
        margin-bottom: 20px;

        svg {
          color: ${colors.bg2};
          width: 65px;
          height: 65px;
          border: 2px solid ${colors.bg1};
          text-align: center;
          border-radius: 50%;
          padding: 15px;
          margin-right: 20px;

          @media (max-width: 991px) {
            font-size: 26px;
            width: 50px;
            height: 50px;
            padding-top: 10px;
            margin-right: 12px;
          }
        }
        h5 {
          color: ${colors.black2};
          font-weight: 600;
          margin-bottom: 8px;

          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
        p {
          font-size: 14px;
          color: ${colors.text3};
          a {
            font-size: 14px;
            color: ${colors.text3};

            &:hover {
              color: ${colors.bg1};
            }

            @media (max-width: 991px) {
              font-size: 13px;
            }
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 20px;
        }
      }

      .contact-social {
        margin-top: 45px;
        ul.social {
          li {
            a {
              i {
                color: ${colors.bg1};

                :hover {
                  color: ${colors.bg2};
                }
              }
            }
          }
        }

        @media (max-width: 767px) {
          margin-top: 30px;
          margin-bottom: 40px;
        }
      }

      .contact-form {
        margin-bottom: 70px;
        .form-title {
          h4 {
            color: ${colors.black1};
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 30px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              background: ${colors.bg1};
              width: 50px;
              height: 2px;
              bottom: 0;
              left: 0;
            }

            @media (max-width: 575px) {
              font-size: 20px;
            }
          }
        }
        .form-box {
          form.form {
            p.form-control {
              padding: 0;
              width: auto;
              height: auto;
              background: transparent;
              border: none;
              margin-bottom: 22px;
              position: relative;

              input {
                width: 100%;
                height: 46px;
                background-color: #ffffff;
                font-size: 14px;
                padding: 15px 20px;
                color: ${colors.black1};
                border: 1px solid ${colors.border3};
                border-radius: 8px;

                &::placeholder {
                  font-size: 14px;
                  color: ${colors.text2};
                }

                &:focus {
                  border-color: ${colors.bg1};
                }

                @media (max-width: 480px) {
                  height: 38px;
                }
              }

              textarea {
                width: 100%;
                height: 135px;
                background-color: #ffffff;
                font-size: 14px;
                padding: 15px 20px;
                color: ${colors.black1};
                border: 1px solid ${colors.border3};
                border-radius: 8px;

                &::placeholder {
                  font-size: 14px;
                  color: ${colors.text2};
                }

                &:focus {
                  border-color: ${colors.bg1};
                }

                @media (max-width: 480px) {
                  height: 120px;
                }
              }

              span.contact_input-msg {
                color: ${colors.red};
                font-weight: 300;
                position: absolute;
                bottom: -20px;
                left: 0;
              }
            }

            p.form-control.success {
              input,
              textarea {
                border: 2px solid ${colors.green};
              }

              &::before {
                position: absolute;
                content: "\f058";
                font-family: "Line Awesome Free";
                font-size: 24px;
                color: ${colors.green};
                font-weight: 900;
                top: 5px;
                right: 10px;
              }
            }

            p.form-control.error {
              input,
              textarea {
                border: 2px solid ${colors.red};
              }

              &::before {
                position: absolute;
                content: "\f06a";
                font-family: "Line Awesome Free";
                font-size: 24px;
                color: ${colors.red};
                font-weight: 900;
                top: 5px;
                right: 10px;
              }
            }

            p.form-control.error {
              span {
                visibility: visible;
              }
            }

            button {
              font-size: 16px;
              color: #fff;
              background: ${colors.gr_bg};
              width: 100%;
              height: 48px;
              font-weight: 500;
              border: none;
              border-radius: 8px;
              text-transform: uppercase;

              &:hover {
                background: ${colors.gr_bg2};

                i {
                  color: #ffffff;
                }
              }

              @media (max-width: 575px) {
                font-size: 14px;
              }

              @media (max-width: 480px) {
                height: 38px;
              }
            }
          }
        }

        @media (max-width: 767px) {
          margin-bottom: 40px;
        }
      }
      .google-map-area {
        width: 100%;

        @media (max-width: 767px) {
          height: 370px;
        }

        @media (max-width: 480px) {
          height: 320px;
        }
      }

      @media (max-width: 767px) {
        padding: 30px 0 0;
      }
    }
  }
`;
