import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";
import { useTranslation } from "react-i18next";

export const AbstractSection = ({ data }) => {
  const { t } = useTranslation();

  return (
    <section className="deadline" id="deadline">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <Heading
                tag="h5"
                className="strategy-title"
                title={"Submission Deadline"}
              />
              <div className="list">
                {data.deadlines.map((deadline, index) => (
                  <div className="dead">
                    <div className="content">
                      <div className="step">
                        <span>{t("Step")}</span>
                        <div className="circle">{deadline.step}</div>
                      </div>
                      <div className="info">
                        <h6>{deadline.title}</h6>
                        <span>
                          {t("Deadline")}: <strong>{deadline.deadline}</strong>
                        </span>
                      </div>
                    </div>
                    {index !== 0 ? (
                      <div className="link">
                        <span></span>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>

              <div className="apply-button">
                <a
                  target="_blank"
                  className="apply"
                  href={data.registration.url}
                >
                  {t(data.registration.title)}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
