import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LANGUAGE_KEYS } from "../utilities/constants";
import { EVENT_TOPICS } from "../utilities/eventUtils";
import { useEventListener } from "./useEventListener";

export const useCurrentLanguage = () => {
  const { i18n } = useTranslation();
  const [languageCode, setLanguageCode] = useState(
    LANGUAGE_KEYS[i18n?.language?.toUpperCase()]
  );

  useEventListener(
    EVENT_TOPICS.ON_LANGUAGE_CHANGED,
    ({ language }) => {
      setLanguageCode(LANGUAGE_KEYS[language.toUpperCase()]);
    },
    [languageCode]
  );

  useEffect(() => {
    setLanguageCode(LANGUAGE_KEYS[i18n?.language.toUpperCase()]);
  }, [i18n?.language]);

  return [languageCode];
};
