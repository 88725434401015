import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

export const SpecialIssueSection = ({ data }) => {
  const { special_issue: content } = data;

  return (
    <section className="special-issue" id="special-issue">
      <Container>
        <Row>
          <Col md="12">
            <div className="main">
              <div className="announcement">
                <img
                  src={"assets/images/icons/megaphone.png"}
                  alt="icon"
                  className="icon"
                />

                <div className="announcement-content">
                  {content.text1} <strong>{content.strong_text}</strong>{" "}
                  {content.text2 ?? null}{" "}
                  <a
                    target="_blank"
                    className="post-title"
                    href={content.link.url}
                  >
                    {content.link.text}
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
