import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import { Styles } from "./styles";
import EnglishData from "../../data/student-voice/data.json";
import RussianData from "../../data/student-voice/data-ru.json";
import UzbekData from "../../data/student-voice/data-uz.json";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import { colors } from "../../components/common/element/elements";
import { Icon } from "@iconify/react";
import Heading from "../../components/shared/Heading";

const StudentVoice = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);

  return (
    <div className="main-wrapper student-voice-page">
      <BreadcrumbBox
        title="Student Voice"
        image="/assets/images/student-voice.jpg"
      />
      <Styles>
        <section className="student-voice-area">
          <Container>
            <Row>
              <Col xs="12">
                <div className="student-voice-content">
                  <div className="section">
                    <Heading
                      tag="h5"
                      className="strategy-title"
                      title={data?.section1?.title}
                    />

                    <p className="pb-3">{data?.section1?.subtitle1}</p>
                    <p className="pb-3">
                      {data?.section1?.subtitle2?.prelink}
                      <a href={`mailto:${data?.section1?.subtitle2?.prelink}`}>
                        {data?.section1?.subtitle2?.link}
                      </a>
                      {data?.section1?.subtitle2?.postlink}
                    </p>
                  </div>

                  <div className="section">
                    <Heading
                      tag="h5"
                      className="strategy-title"
                      title={data?.section2?.title}
                    />
                    <p className="pb-3">{data?.section2?.subtitle}</p>
                  </div>

                  <div className="section">
                    <Heading
                      tag="h5"
                      className="strategy-title"
                      title={data?.section3?.title}
                    />
                    <p className="pb-3">{data?.section3?.subtitle}</p>
                  </div>

                  <div className="section">
                    <Heading
                      tag="h5"
                      className="strategy-title"
                      title={data?.section4?.title}
                    />
                    <p className="pb-3">{data?.section4?.subtitle1}</p>
                    <p className="pb-3">{data?.section4?.subtitle2}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Styles>
    </div>
  );
};

export default StudentVoice;
