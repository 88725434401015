import React from "react";

import Heading from "../../../components/shared/Heading";

const VerticalCategoriesBlock = ({ categories, language }) => {
  return (
    <div className="news-side-panel-wrapper">
      <Heading tag="h5" className="news-side-panel-title" title="Categories" />
      {categories.map((category) => (
        <div className="news-category-panel-item" key={category?.id}>
          {category?.title?.[language]}
        </div>
      ))}
    </div>
  );
};

export default VerticalCategoriesBlock;
