import { Skeleton } from "@mui/material";
import React from "react";

const NewsListSkeleton = () => {
  return (
    <div>
      <Skeleton
        className="news-list-item"
        variant="rounded"
        height={509}
        animation="wave"
      />
      <Skeleton
        className="news-list-item"
        variant="rounded"
        height={509}
        animation="wave"
      />
      <Skeleton
        className="news-list-item"
        variant="rounded"
        height={509}
        animation="wave"
      />
    </div>
  );
};

export default NewsListSkeleton;
