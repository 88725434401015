import {
  createStudent,
  createEmployee,
  uploadEmployeeFiles,
} from "../repositories/userRepository";

export default {
  createStudent: async (body) => await createStudent(body),

  createEmployee: async (body) => await createEmployee(body),

  uploadEmployeeFiles: async (body) => await uploadEmployeeFiles(body),
};
