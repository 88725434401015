import { useState } from "react";

import { BE_ERROR_MESSAGES } from "../utilities/constants";
import { useStatusContext } from "./contextHooks/useStatusContext";
import AlertComponent from "../components/shared/AlertComponent";

export const useBeManualFetcher = (hasErrorMessage = true) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const { onSuccessStatus, onErrorStatus } = useStatusContext();

  const onFetch = ({
    action,
    onLoad,
    actionWhenError = () => {},
    successMessage = false,
  }) => {
    setIsLoading(true);
    action()
      .then((data) => {
        onLoad(data);
        successMessage &&
          onSuccessStatus({
            title: "Operation is succeeded",
            message: successMessage,
          });
      })
      .catch((err) => {
        const errorMessage = err?.beErrorCode
          ? BE_ERROR_MESSAGES[err.beErrorCode]
          : "Please, contact us about problem you have faced at " +
            '<a href="https://t.me/ptuuz_bot">https://t.me/ptuuz_bot</a>' +
            " and we will try to solve your problem as soon as possible";

        hasErrorMessage &&
          AlertComponent({
            title: "Oops, something went wrong",
            html:
              "Please, contact us about problem you have faced at " +
              '<a href="https://t.me/ptuuz_bot">https://t.me/ptuuz_bot</a>' +
              " and we will try to solve your problem as soon as possible",
            icon: "error",
          });

        actionWhenError();
        setHasError(true);
      })
      .finally(() => setIsLoading(false));
  };

  return [onFetch, isLoading, hasError];
};
