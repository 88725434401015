import React from "react";
import MobileMenuNavigationitem from "./MobileMenuNavigationitem";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import { colors } from "../../common/element/elements";
import { Link } from "react-router-dom";

export const NavigationWithSubmenu = ({
  onClick,
  submenuLinks,
  title,
  link,
}) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-menu-item">
        <button
          className="mb-menu-button"
          onClick={() => setShowSubmenu((prev) => !prev)}
        >
          <Link to={link}>{t(title)}</Link>{" "}
          <div className={`accordion-icon ${showSubmenu ? "icon-rotate" : ""}`}>
            <Icon
              icon={"ph:caret-circle-right-duotone"}
              fontSize={20}
              color={colors.bg2}
            />
          </div>
        </button>
      </div>
      {showSubmenu
        ? submenuLinks.map((menuLink) => (
            <MobileMenuNavigationitem
              link={menuLink.link}
              title={menuLink.title}
              onClick={onClick}
              className={"pl-3"}
            />
          ))
        : null}
    </>
  );
};
