import Swal from "sweetalert2";

const AlertComponent = ({ title, text, icon, html }) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: icon,
    html: html,
    width: "60vw",
    padding: "20px",
    confirmButtonColor: "#662a30",
    confirmButtonText: "Close",
  });
};

export default AlertComponent;
