import React from "react";
import CourseDetailsStructureTab from "../CourseDetailsStructureTab";

export const CPDCourseContent = ({ courseData }) => {
  return (
    <div className="course-tab-list">
      <CourseDetailsStructureTab
        data={courseData?.structure}
        title={"List of CPD courses"}
      />
    </div>
  );
};
