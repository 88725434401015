import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { colors } from "../../../components/common/element/elements";
import { unixDateConvertor } from "../../../utilities/dateTimeUtils";

const NewsCard = ({
  image,
  title,
  createdDate,
  categories,
  onClick,
  language,
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.01 }}
      className="news-list-item"
      onClick={onClick}
    >
      <img src={image} alt={title} className="news-list-image" />
      <div className="news-list-content-wrapper">
        <h4 className="news-list-header">{title}</h4>
        <div className="news-list-details-wrapper">
          <div className="news-list-news-date">
            <Icon
              icon="ph:calendar-duotone"
              fontSize={24}
              className="mr-1"
              color={colors.bg2}
            />
            <p>{unixDateConvertor(createdDate)}</p>
          </div>
          <div className="news-list-news-category-wrapper">
            {categories?.map((category) => (
              <h5 key={category?.id} className="news-list-news-category">
                {category?.title?.[language]}
              </h5>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewsCard;
