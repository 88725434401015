import React from "react";
import { useTranslation } from "react-i18next";

const Heading = ({ title, className, ...props }) => {
  const { t } = useTranslation();

  return <props.tag className={className}>{t(title)}</props.tag>;
};

export default Heading;
