import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Heading from "../../../../components/shared/Heading";

export const SponsorsSection = ({ data }) => {
  return (
    <section className="sponsors" id="sponsors">
      <Container>
        <Row>
          <Col md="12">
            <Heading tag="h5" className="strategy-title" title={"Sponsors"} />
          </Col>
          <Col md="12">
            <div className="sponsors-list">
              {data?.sponsors?.map((partner, i) => (
                <div
                  className="sponsors-item d-flex"
                  key={i}
                  onClick={() => window.open(partner?.partnerLink, "_blank")}
                >
                  <div className="sponsors-item-image">
                    <img
                      src={`/assets/images/${partner?.partnerImage}`}
                      alt={partner?.partnerName}
                      className="img-fluid"
                      style={{ height: "100px" }}
                    />
                  </div>
                  <div className="sponsors-item-content">
                    <h6 className="mb-3">{partner?.partnerName}</h6>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
