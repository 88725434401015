import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/home/Home";
import About from "../pages/about/About";
import CourseList from "../pages/courses/CourseList";
import CourseDetails from "../pages/courses/CourseDetails";
import Contact from "../pages/contact/Contact";
import Apply from "../pages/apply/Apply";
import StudentsApplicationForm from "../pages/apply/StudentsApplicationForm";
import EmployeeApplicationForm from "../pages/apply/EmployeeApplicationForm";
import Strategy from "../pages/strategy/Strategy";
import Careers from "../pages/career/Careers";
import Partners from "../pages/partners/Partners";
import JobDescription from "../pages/career/JobDescription";
import NewsPage from "../newsPage/pages/NewsPage";
import NewsDetailsPage from "../newsPage/pages/NewsDetailsPage";
import CampusMap from "../pages/campusMap/CampusMap";
import FAQsPage from "../faqsPage/FAQsPage";
import AcademicRegulations from "../pages/regulations/AcademicRegulations";
import Team from "../pages/career/Team";
import { TeamDetails } from "../pages/career/TeamDetails/index";
import ApplyPractitioners from "../pages/apply/ApplyPractitioners";
import Conference from "../pages/conference/Conference";
import CPD from "../pages/cpd";
import International from "../pages/international/International";
import StudentLife from "../pages/student-life/StudentLife";
import StudentSupportService from "../pages/student-support-service/StudentSupportService";
import StudentInformationSupport from "../pages/student-information-support/StudentInformationSupport";
import Social from "../pages/social/Social";
import TuitionFees from "../pages/tuition-fees/TuitionFees";
import AboutConference from "../pages/conference/AboutConference";
import ConferenceProgram from "../pages/conference/ConferenceProgram";
import CallForAbstract from "../pages/conference/CallForAbstract";
import AntiCorruption from "../pages/anti-corruption/AntiCorruption";
import Wellbeing from "../pages/wellbeing/Wellbeing";
import Accommodation from "../pages/accommodation/Accommodation";
import StudentSupportServiceContactUs from "../pages/student-support-service-contact-us/StudentSupportServiceContactUs";
import StudentsUnion from "../pages/students-union/StudentsUnion";
import StudentVoice from "./../pages/student-voice/StudentVoice";

const Pages = () => (
  <Routes>
    <Route exact path={"/"} element={<Home />} />
    <Route path={"/study"} element={<CourseList />} />

    <Route path={"/apply-students"} element={<Apply />} />
    <Route
      path={"/students-apply-form"}
      element={<StudentsApplicationForm />}
    />
    <Route
      exact
      path={"/apply-practitioners"}
      element={<ApplyPractitioners />}
    />
    <Route
      path={"/employees-apply-form/:id"}
      exact
      element={<EmployeeApplicationForm />}
    />
    <Route exact path={"/vacancies"} element={<Careers />} />
    <Route exact path={"/team"} element={<Team />} />
    <Route exact path={"/team/:id"} element={<TeamDetails />} />
    <Route exact path={"/programme-details/:id"} element={<CourseDetails />} />
    <Route path={"/job-description/:id"} element={<JobDescription />} />

    <Route exact path={"/partners"} element={<Partners />} />
    <Route exact path={"/strategy"} element={<Strategy />} />
    <Route path={"/about"} element={<About />} />
    <Route path="/news" exact element={<NewsPage />} />
    <Route path="/news/:id" exact element={<NewsDetailsPage />} />
    <Route path={"/contact"} element={<Contact />} />
    <Route path={"/campus-map"} element={<CampusMap />} />
    <Route path={"/faqs"} element={<FAQsPage />} />
    <Route path={"/academic-regulations"} element={<AcademicRegulations />} />
    <Route path={"/conference"} element={<Conference />} />
    <Route path={"/about-conference"} element={<AboutConference />} />
    <Route path={"/conference-program"} element={<ConferenceProgram />} />
    <Route
      path={"/conference-call-for-abstract"}
      element={<CallForAbstract />}
    />
    <Route path={"/cpd"} element={<CPD />} />
    <Route path={"/international"} element={<International />} />
    <Route path={"/student-life"} element={<StudentLife />} />
    <Route
      path={"/student-support-service"}
      element={<StudentSupportService />}
    />
    <Route
      path={"/student-support-service-contact-us"}
      element={<StudentSupportServiceContactUs />}
    />
    <Route
      path={"/student-information-support"}
      element={<StudentInformationSupport />}
    />
    <Route path={"/social"} element={<Social />} />
    <Route path={"/tuition-fees"} element={<TuitionFees />} />
    <Route path={"/anti-corruption"} element={<AntiCorruption />} />
    <Route path={"/wellbeing"} element={<Wellbeing />} />
    <Route path={"/accommodation"} element={<Accommodation />} />
    <Route path={"/students-union"} element={<StudentsUnion />} />
    <Route path={"/student-voice"} element={<StudentVoice />} />
  </Routes>
);

export default Pages;
