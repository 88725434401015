import styled from "styled-components";
import { colors, border } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
  /* Course Grid */
  .course-details-feature .course-grid-area {
    padding: 50px 0;
    .course-items {
      .course-item {
        border: 1px solid ${colors.border1};
        border-radius: 8px;
        transition: all 0.2s ease;
        overflow: hidden;
        margin-bottom: 30px;

        .course-image {
          width: 100%;
          height: 240px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 8px 100px 0 0;
          position: relative;

          .author-img {
            position: absolute;
            left: 20px;
            bottom: 20px;

            img {
              max-width: 40px;
              border-radius: 50%;
              margin-right: 5px;
            }

            .title {
              background: #ffffff;
              padding: 3px 8px;
              border-radius: 8px;

              p {
                font-size: 12px;
                color: ${colors.black1};
                font-weight: 500;
                margin-bottom: -4px;
              }

              span {
                font-size: 11px;
                color: ${colors.text3};
                font-weight: 500;
              }
            }
          }

          .course-price {
            p {
              font-size: 16px;
              color: #ffffff;
              background: ${colors.bg1};
              position: absolute;
              right: 20px;
              bottom: 20px;
              padding: 8px 10px;
              font-weight: 500;
              border-radius: 8px;
            }
          }

          @media (max-width: 767px) {
            height: 185px;
          }
        }

        .course-content {
          background: #fff;
          padding: 20px 25px;

          h6.heading {
            a {
              color: ${colors.black1};
              font-weight: 600;
              display: inline-block;
              margin-bottom: 12px;

              &:hover {
                color: ${colors.green};
              }
            }
          }

          p.desc {
            font-size: 14px;
            color: ${colors.text3};
            line-height: 25px;
            border-bottom: 1px solid ${colors.border1};
            padding-bottom: 10px;
            margin-bottom: 12px;
          }

          .course-face {
            .duration,
            .student {
              p {
                font-size: 13px;
                color: ${colors.text3};

                i {
                  font-size: 16px;
                  color: ${colors.green};
                  vertical-align: text-bottom;
                  margin-right: 3px;
                }
              }
            }

            .rating {
              ul {
                li {
                  margin-right: 0;

                  i {
                    font-size: 14px;
                    color: ${colors.yellow};
                  }

                  &:last-child {
                    font-size: 13px;
                    color: ${colors.text3};
                  }
                }
              }
            }
          }
        }

        &:hover {
          box-shadow: 0 12px 25px rgba(0, 0, 0, 0.07);
        }
      }
    }

    @media (max-width: 767px) {
      padding: 40px 0 30px;
    }
  }

  /* Course List */
  .course-list-area {
    padding: 70px 0;
    .course-items2 {
      .course-item {
        transition: all 0.2s ease;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 8px 20px 5px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;

        .course-image-box {
          .course-image {
            width: 290px;
            height: 212px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 8px 0 0 0;
            position: relative;

            .author-img {
              position: absolute;
              left: 20px;
              top: 20px;

              img {
                max-width: 40px;
                border-radius: 50%;
                margin-right: 5px;
              }

              .title {
                background: #ffffff;
                padding: 3px 8px;
                border-radius: 8px;

                p {
                  font-size: 12px;
                  color: ${colors.black1};
                  font-weight: 500;
                  margin-bottom: -4px;
                }

                span {
                  font-size: 11px;
                  color: ${colors.text3};
                  font-weight: 500;
                }
              }

              @media (max-width: 991px) {
                top: unset;
                bottom: 20px;
              }
            }

            .course-price {
              p {
                font-size: 16px;
                color: #ffffff;
                background: ${colors.bg1};
                position: absolute;
                right: 20px;
                top: 20px;
                padding: 8px 10px;
                font-weight: 500;
                border-radius: 8px;

                @media (max-width: 991px) {
                  top: unset;
                  bottom: 20px;
                }
              }
            }

            @media (max-width: 1000px) {
              width: 100%;
              height: 300px;
            }

            @media (max-width: 768px) {
              height: 244px;
            }
          }
        }

        .course-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background: #fff;
          padding: 20px 25px;

          div {
            h6.heading {
              padding-bottom: 12px;
              margin-bottom: 20px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.bg1};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              a {
                color: ${colors.black1};
                font-weight: 600;
                display: inline-block;

                &:hover {
                  color: ${colors.bg1};
                }
              }
            }

            p.desc {
              font-size: 14px;
              color: ${colors.text3};
              line-height: 25px;
              margin-bottom: 15px;
            }
          }
        }

        &:hover {
          box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
        }

        @media (max-width: 991px) {
          display: block !important;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 40px 0 30px;
    }
  }

  /* Course Details */
  .course-details-area {
    padding: 50px 0 20px;
    .course-details-top {
      .heading {
        h4 {
          color: ${colors.black1};
          font-weight: 600;
          line-height: 35px;
          margin-bottom: 25px;

          @media (max-width: 575px) {
            font-size: 20px;
          }
        }
      }
      .course-top-overview {
        margin-bottom: 25px;
        .overviews {
          .author {
            margin-right: 20px;
            padding-right: 20px;
            margin-top: -3px;
            border-right: 1px solid ${colors.border3};
            img {
              float: left;
              max-width: 50px;
              border-radius: 50%;
              margin-right: 10px;
            }
            .author-name {
              float: left;
              margin-top: 3px;
              h6 {
                color: ${colors.black2};
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 5px;

                @media (max-width: 767px) {
                  font-size: 14px;
                }
              }
              p {
                font-size: 15px;
                color: ${colors.text3};
                font-weight: 500;

                @media (max-width: 767px) {
                  font-size: 14px;
                }
              }
            }
          }

          .category {
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid ${colors.border3};
            h6 {
              color: ${colors.black2};
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 5px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            p {
              font-size: 15px;
              color: ${colors.text3};
              font-weight: 500;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }

          .rating {
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid ${colors.border3};
            h6 {
              color: ${colors.black2};
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 5px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            ul {
              li {
                margin-right: 1px;
                i {
                  font-size: 16px;
                  color: ${colors.yellow};
                }
                &:last-child {
                  font-size: 15px;
                  color: ${colors.text3};
                  font-weight: 500;
                  margin-left: 5px;

                  @media (max-width: 767px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .price {
            h6 {
              color: ${colors.black2};
              font-weight: 600;
              text-transform: uppercase;
              margin-bottom: 5px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
            p {
              font-size: 15px;
              color: ${colors.text3};
              font-weight: 500;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
        }

        @media (max-width: 480px) {
          display: none;
        }
      }
      .course-details-banner {
        margin-bottom: 40px;
        img {
          border-radius: 8px;
        }
      }
      .course-tab-list {
        .nav {
          display: inline-block;
          border-radius: 8px;
          box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
          margin-bottom: 35px;
          .nav-item {
            display: inline-block;
            a.nav-link {
              font-size: 14px;
              color: ${colors.black2};
              font-weight: 500;
              text-transform: uppercase;
              padding: 10px 25px;
              border-radius: 8px;

              @media (max-width: 991px) {
                padding: 12px 16px 9px;
              }
            }
            a.nav-link.active {
              background: ${colors.gr_bg};
              color: #ffffff;
            }
          }
        }
        .overview-tab {
          .job-duties {
            ul {
              margin-bottom: 20px;
              li {
                font-size: 14px;
                color: #969696;
                line-height: 15px;
                margin-bottom: 15px;

                i {
                  font-size: 20px;
                  color: #662a30;
                  float: left;
                  line-height: 27px;
                  margin-right: 10px;
                }
              }
            }
          }
          h5 {
            color: ${colors.black2};
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 20px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              background: ${colors.bg1};
              width: 50px;
              height: 2px;
              bottom: 0;
              left: 0;
            }

            @media (max-width: 575px) {
              font-size: 17px;
            }
          }
          p {
            font-size: 15px;
            color: ${colors.text2};
            line-height: 25px;

            @media (max-width: 575px) {
              font-size: 14px;
            }
          }

          .course-desc {
            margin-bottom: 40px;
            .course-desc-container-title {
              line-height: 25px;
              margin-bottom: 10px;
            }

            .course-desc-container {
              display: flex;
              column-gap: 10px;
              line-height: 25px;
              margin-bottom: 10px;
              border-radius: 8px;
              box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
              padding: 10px 15px;

              i {
                font-size: 20px;
                color: ${colors.bg1};
                float: left;
                line-height: 27px;
                margin-right: 10px;
              }
            }
          }

          .course-feature {
            margin-bottom: 40px;
            ul {
              margin-top: 20px;
              li {
                font-size: 14px;
                color: ${colors.text3};
                line-height: 25px;
                margin-bottom: 10px;
                i {
                  font-size: 20px;
                  color: ${colors.bg1};
                  float: left;
                  height: 40px;
                  line-height: 27px;
                  margin-right: 10px;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        .course-learn {
          margin-bottom: 40px;
          font-size: 15px;

          p {
            font-size: 15px;
          }

          ul {
            margin-top: 20px;

            li {
              display: flex;
              align-items: center;
              column-gap: 10px;
              border-radius: 8px;
              box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
              padding: 10px 15px;
              font-size: 15px;
              color: ${colors.text3};
              line-height: 25px;
              margin-bottom: 15px;
            }

            li.job {
              i {
                color: ${colors.bg1};
                font-size: 20px;
              }
            }

            .course-details-icon-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;

              color: ${colors.text6};
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .course-share {
          ul.social {
            margin-top: 30px;
            li {
              a {
                text-align: center;
                position: relative;
                height: 18px;
                display: inline-block;

                &:before {
                  content: "";
                  position: absolute;
                  border-width: 9px 17px;
                  border-style: solid;
                  border-top-color: transparent;
                  border-right-color: transparent;
                  border-left-color: transparent;
                  top: -18px;
                  left: 0;
                  z-index: 1;
                  transition: all 0.2s ease;
                }

                &:after {
                  content: "";
                  position: absolute;
                  border-width: 9px 17px;
                  border-style: solid;
                  border-right-color: transparent;
                  border-bottom-color: transparent;
                  border-left-color: transparent;
                  bottom: -18px;
                  left: 0;
                  z-index: 1;
                  transition: all 0.2s ease;
                }

                i {
                  font-size: 14px;
                  color: #ffffff;
                  width: 34px;
                }

                &:hover {
                  background-color: ${colors.green} !important;

                  &:before {
                    border-bottom-color: ${colors.green} !important;
                  }

                  &:after {
                    border-top-color: ${colors.green} !important;
                  }
                }
              }

              &:nth-child(1) {
                a {
                  background-color: #4267b2;

                  &:before {
                    border-bottom-color: #4267b2;
                  }

                  &:after {
                    border-top-color: #4267b2;
                  }
                }
              }

              &:nth-child(2) {
                a {
                  background-color: #1da1f2;

                  &:before {
                    border-bottom-color: #1da1f2;
                  }

                  &:after {
                    border-top-color: #1da1f2;
                  }
                }
              }

              &:nth-child(3) {
                a {
                  background-color: #2867b2;

                  &:before {
                    border-bottom-color: #2867b2;
                  }

                  &:after {
                    border-top-color: #2867b2;
                  }
                }
              }

              &:nth-child(4) {
                a {
                  background-color: #dd1343;

                  &:before {
                    border-bottom-color: #dd1343;
                  }

                  &:after {
                    border-top-color: #dd1343;
                  }
                }
              }

              &:nth-child(5) {
                a {
                  background-color: #ea4c89;

                  &:before {
                    border-bottom-color: #ea4c89;
                  }

                  &:after {
                    border-top-color: #ea4c89;
                  }
                }
              }
            }
          }
        }

        .curriculum-tab {
          .course-curriculum {
            margin-bottom: 40px;
            h5 {
              color: ${colors.black2};
              font-weight: 600;
              padding-bottom: 10px;
              margin-bottom: 20px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              @media (max-width: 575px) {
                font-size: 17px;
              }
            }
            p {
              font-size: 15px;
              color: ${colors.text2};
              line-height: 25px;

              @media (max-width: 575px) {
                font-size: 14px;
              }
            }
          }
          .course-element {
            h5 {
              color: ${colors.black2};
              font-weight: 600;
              padding-bottom: 10px;
              margin-bottom: 20px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              @media (max-width: 575px) {
                font-size: 17px;
              }
            }
            .course-item {
              margin-bottom: 10px;
              button.course-button {
                border: none;
                background: transparent;
                margin-bottom: 15px;
                display: block;
                width: 100%;
                text-align: left;
                padding: 0;
                font-size: 18px;
                color: ${colors.black2};
                font-weight: 500;
                span {
                  float: right;
                  font-size: 14px;
                  color: ${colors.text3};
                  font-weight: 400;
                }
              }

              .course-content {
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s ease-in-out;
                ul {
                  li {
                    border-bottom: 1px solid ${colors.border3};
                    margin-left: 25px;
                    padding: 12px 0;
                    span.play-icon {
                      font-size: 14px;
                      color: ${colors.text3};
                      margin-right: 20px;
                      i {
                        color: ${colors.green};
                        border: 1px solid ${colors.green};
                        font-size: 22px;
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        padding-left: 3px;
                        text-align: center;
                        line-height: 29px;
                        vertical-align: middle;
                        margin-right: 10px;
                      }
                    }
                    span.lecture-title {
                      font-size: 15px;
                      color: ${colors.black2};

                      @media (max-width: 575px) {
                        font-size: 14px;
                      }
                    }
                    span.lecture-duration {
                      float: right;
                      font-size: 14px;
                      color: ${colors.text3};
                      line-height: 28px;
                      font-weight: 400;
                    }
                  }
                }
              }

              .course-content.show {
                max-height: 100%;
                margin-bottom: 40px;
              }

              &:last-child {
                margin-bottom: 40px;
              }
            }
          }
        }

        .instructor-tab {
          h5 {
            color: ${colors.black2};
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 15px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              background: ${colors.bg1};
              width: 50px;
              height: 2px;
              bottom: 0;
              left: 0;
            }

            @media (max-width: 575px) {
              font-size: 17px;
            }
          }

          .instructor-description {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 30px;
          }

          .course-postTitle {
            font-size: 15px;
          }
          .instructor-item {
            margin-bottom: 30px;

            .instructor-img {
              img {
                border-radius: 8px;
              }
            }

            .instructor-content {
              position: relative;

              .instructor-box {
                box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
                padding: 30px 30px 40px;
                background: #ffffff;
                border-radius: 8px;

                .top-content {
                  margin-bottom: 10px;
                  padding-bottom: 10px;
                  border-bottom: 1px solid ${colors.border3};

                  .instructor-name {
                    width: 100%;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    row-gap: 10px;

                    h6 {
                      color: ${colors.black2};
                      font-weight: 600;
                      text-transform: uppercase;

                      @media (max-width: 575px) {
                        font-size: 14px;
                      }
                    }
                    p {
                      font-size: 14px;
                      color: ${colors.text3};
                      font-weight: 500;
                    }
                    .course-structure-details {
                      p {
                        font-size: 14px;
                        color: ${colors.black1};
                        font-weight: 500;
                      }
                      i {
                        color: ${colors.bg2};
                      }
                    }
                  }
                }

                .instructor-desk {
                  .instructor-desk-text {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 25px;
                  }
                  ul {
                    margin-top: 10px;
                    li {
                      font-size: 14px;
                      color: ${colors.text3};
                      line-height: 15px;
                      margin-bottom: 15px;
                      display: flex;
                      column-gap: 10px;

                      p {
                        font-size: 15px;
                        color: ${colors.text2};
                        line-height: 25px;

                        @media (max-width: 575px) {
                          font-size: 14px;
                        }

                        strong {
                          font-weight: 400;
                        }
                      }
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .review-tab {
          .review-comments {
            margin-bottom: 40px;
            h5 {
              color: ${colors.black2};
              font-weight: 600;
              padding-bottom: 10px;
              margin-bottom: 35px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              @media (max-width: 575px) {
                font-size: 17px;
              }
            }
            .comment-box {
              border-bottom: 1px solid ${colors.border1};
              padding-bottom: 20px;
              margin-bottom: 25px;
              .comment-image {
                img {
                  max-width: 100px;
                  border-radius: 8px;
                  margin-right: 20px;
                }
              }
              .comment-content {
                .content-title {
                  .comment-writer {
                    h6 {
                      color: ${colors.black2};
                      font-weight: 600;
                      margin-bottom: 10px;

                      @media (max-width: 575px) {
                        font-size: 14px;
                      }
                    }
                    p {
                      font-size: 12px;
                      color: ${colors.text3};
                      margin-bottom: 5px;
                    }
                    ul {
                      margin-bottom: 8px;
                      li {
                        margin-right: 1px;
                        i {
                          font-size: 16px;
                          color: ${colors.yellow};
                        }
                        &:last-child {
                          font-size: 13px;
                          color: ${colors.text2};
                          margin-left: 5px;
                        }
                      }
                    }
                  }
                  .reply-btn {
                    button {
                      font-size: 14px;
                      color: ${colors.green};
                      background: transparent;
                      border: 1px solid ${colors.border3};
                      font-weight: 500;
                      border-radius: 25px;
                      padding: 4px 12px 3px;
                      margin-top: 10px;
                      i {
                        font-size: 17px;
                        vertical-align: text-top;
                      }
                      &:hover {
                        color: #ffffff;
                        background: ${colors.gr_bg};
                        border-color: ${colors.green};
                      }
                    }
                  }
                }
                .comment-desc {
                  p {
                    font-size: 14px;
                    color: ${colors.text1};
                    line-height: 25px;
                  }
                }
              }
              &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
              }
            }
          }

          .review-form {
            h5 {
              color: ${colors.black2};
              font-weight: 600;
              padding-bottom: 10px;
              margin-bottom: 25px;
              position: relative;
              &:before {
                position: absolute;
                content: "";
                background: ${colors.green};
                width: 50px;
                height: 2px;
                bottom: 0;
                left: 0;
              }

              @media (max-width: 575px) {
                font-size: 17px;
              }
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }

    .single-details-sidbar {
      .course-details-feature {
        box-shadow: 0 1px 10px 5px rgb(0 0 0 / 3%);
        border-radius: ${border.primaryRadius};
        padding: 20px;
        margin-bottom: 30px;

        h5.title {
          color: ${colors.black1};
          font-weight: 600;
          padding-bottom: 10px;
          margin-bottom: 20px;
          position: relative;
          font-size: 18px;

          &:before {
            position: absolute;
            content: "";
            background: ${colors.bg1};
            width: 50px;
            height: 2px;
            bottom: 0;
            left: 0;
          }

          @media (max-width: 575px) {
            font-size: 17px;
          }
        }

        ul.feature-list {
          margin-bottom: 20px;
          li {
            border-top: 1px solid ${colors.border3};
            padding: 12px 0;
            display: flex;

            p {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              strong {
                font-size: 14px;
                color: ${colors.black2};
                font-weight: 500;
              }

              span {
                font-size: 13px;
                color: ${colors.text3};
                font-weight: 400;
                line-height: 20px;
              }
            }

            i {
              font-size: 20px;
              color: ${colors.bg1};
              vertical-align: top;
              margin-right: 2px;
            }

            &:first-child {
              border-top: none;
              padding-top: 0;
            }
            &:last-child {
              padding-bottom: 0;
            }
          }
        }

        .enroll-btn-wrapper {
          width: 100%;

          button.enroll-btn {
            font-size: 16px;
            color: #fff;
            background: ${colors.gr_bg};
            display: inline-block;
            width: 100%;
            font-weight: 500;
            border: none;
            padding: 9px;
            border-radius: 8px;
            &:hover {
              background: ${colors.gr_bg2};
            }

            @media (max-width: 575px) {
              font-size: 14px;
            }
          }

          @media (max-width: 1199px) {
            padding: 12px 15px 15px;
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 20px 0 40px;
    }
  }
`;
