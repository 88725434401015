import React from "react";
import { useTranslation } from "react-i18next";

import Heading from "../../../components/shared/Heading";

const DetailsPageTabInfoBar = ({ courseLeader, courseCategory, price }) => {
  const { t } = useTranslation();

  return (
    <div className="course-top-overview">
      <div className="d-flex overviews">
        {courseLeader ? (
          <div className="author">
            <div className="author-name">
              <Heading tag={"h6"} title={"Leader"} />
              <p>{courseLeader}</p>
            </div>
          </div>
        ) : null}
        <div className="category">
          <Heading tag={"h6"} title={"Category"} />
          <p>{courseCategory}</p>
        </div>
        <div className="price">
          <Heading tag={"h6"} title={"Price"} />
          <p>{price}</p>
        </div>
      </div>
    </div>
  );
};

export default DetailsPageTabInfoBar;
