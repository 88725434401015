import styled from "styled-components";
import { colors } from "../../common/element/elements.js";

export const Styles = styled.div`
  .vacancy-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    transition: all 0.2s ease;
    overflow: hidden;
    margin-bottom: 15px;
    padding: 15px 20px;
    box-shadow: 0 5px 25px rgb(0 0 0 / 10%);

    .vacancy-title {
      color: ${colors.black1};
      font-weight: 600;
      max-width: 65%;
    }

    .apply-button-wrapper {
      display: flex;
      align-items: center;
      column-gap: 12px;
      .apply-buttons {
        display: flex;
        align-items: center;
        column-gap: 8px;
        .apply-button {
          font-size: 14px;
          color: #fff;
          border: none;
          background: ${colors.gr_bg};
          text-align: center;
          border-radius: 8px;
          font-size: 14px;
          padding: 8px 20px;

          &:hover {
            background: ${colors.gr_bg2};
          }

          @media (max-width: 767px) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .vacancy-item {
      flex-direction: column;
      row-gap: 24px;
    }
    .apply-button-wrapper {
      width: 100%;
      justify-content: space-between;
    }
    .vacancy-title {
      max-width: 100% !important;
      width: 100%;
      text-align: left;
    }
  }

  @media (max-width: 480px) {
    .apply-button-wrapper {
      flex-direction: column;
      row-gap: 24px;
    }
    .vacancy-title {
      text-align: center;
    }
  }
`;
