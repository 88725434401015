import merge from "lodash/merge";
import {
  getCurrentEnvironment,
  ENVIRONMENTS_ENUMS,
} from "../utilities/environmentUtils";

const baseConfig = require("./repositoryConfig.json");
const prodConfig = require("./repositoryConfig.prod.json");

let environmentOverride;

const currentEnv = getCurrentEnvironment();

switch (currentEnv) {
  case ENVIRONMENTS_ENUMS.PROD:
    environmentOverride = prodConfig;
    break;
  default:
    environmentOverride = {};
    break;
}

merge(baseConfig, environmentOverride);

export const config = { ...baseConfig };
