import styled from "styled-components";
import { colors } from "../../components/common/element/elements";

export const Styles = styled.div`
  .cpd-page {
    .main-content {
      margin: 70px 0 90px;

      .tab-navigation {
        display: flex;
        justify-content: center;
        margin: 30px;

        .tab {
          padding: 10px 30px;

          background: white;
          cursor: pointer;
          color: #000000;
          font-weight: 500;
          box-shadow: 0 8px 25px rgb(0 0 0 / 10%);
          white-space: nowrap;

          :first-child {
            border-radius: 8px 0 0 8px;
          }

          :last-child {
            border-radius: 0 8px 8px 0;
          }
        }

        .tab-active {
          background: ${colors.bg1};
          color: white;
        }
      }
    }
  }
`;
