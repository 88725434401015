import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
} from "react-share";

import { BreadcrumbBox } from "../../components/common/Breadcrumb";
import HorizontalTopNewsComponent from "../components/smartComponents/HorizontalTopNewsComponent";
import Fetcher from "../../components/shared/Fetcher";
import RichTextHTMLParser from "../components/reusableComponents/RichTextHTMLParser";

import newsService from "../service/newsService";
import { IMAGE_KEYS } from "../../utilities/constants";
import { useCurrentLanguage } from "../../hooks/useCurrentLanguage";
import { unixDateConvertor } from "../../utilities/dateTimeUtils";
import { formatToJSON } from "../utilities/formatUtils";
import categoryService from "../service/categoryService";
import { useBeManualFetcher } from "../../hooks/useBeManualFetcher";

import { Styles } from "../styles/newsDetails.js";
import { Icon } from "@iconify/react";
import { colors } from "../../components/common/element/elements";

const NewsDetailsPage = () => {
  const { id } = useParams();
  const [language] = useCurrentLanguage();
  const [onFetch] = useBeManualFetcher();

  const [news, setNews] = useState([]);
  const [newsDescription, setNewsDescription] = useState();

  const fetchNews = useCallback(
    async () => await newsService.getNewsById(id),
    [id]
  );

  const handleOnLoadFetchedNews = useCallback(
    (response) => {
      setNews(response);
      setNewsDescription(response?.description?.[language]);
      handleCategoryFetch(response);
    },
    [setNews, language]
  );

  const handleCategoryFetch = useCallback(
    async (response) => {
      const copiedResponse = { ...response };
      const categoryIds = response?.categoryIds;

      if (categoryIds.length < 1) return setNews(response);

      return await onFetch({
        action: () => categoryService.getCategories({ ids: categoryIds }),
        onLoad: (categoryFetchResponse) => {
          setNews({
            ...copiedResponse,
            categoryIds: categoryFetchResponse?.data,
          });
        },
      });
    },
    [news]
  );

  return (
    <Styles>
      <div className="main-wrapper blog-details-page">
        <BreadcrumbBox
          title="News Details"
          previousPath="/news"
          previousTitle="News"
          image="/assets/images/banner-4.jpg"
        />
        <section className="blog-details-area">
          <Container>
            <Row>
              <Col lg="12" md="12" sm="12">
                <Fetcher action={fetchNews} onLoad={handleOnLoadFetchedNews}>
                  <div className="blog-details-box">
                    <div className="heading">
                      <h4>{news?.title?.[language]}</h4>
                    </div>
                    <div className="news-list-preheader">
                      <div className="news-list-details-wrapper">
                        <div className="news-list-news-date">
                          <Icon
                            icon="ph:calendar-duotone"
                            fontSize={24}
                            color={colors.bg2}
                          />
                          <p>{unixDateConvertor(news?.createdAt)}</p>
                        </div>
                        <div className="news-list-news-category-wrapper">
                          {news?.categoryIds?.map((category) => (
                            <a
                              className="news-list-news-category"
                              key={category}
                            >
                              {category?.title?.[language]}
                            </a>
                          ))}
                        </div>
                      </div>
                      <div className="news-list-details-wrapper">
                        <div className="news-list-news-visitors">
                          <p>Share:</p>
                          <FacebookShareButton
                            url={window.location.href}
                            quote={news?.title?.[language]}
                          >
                            <FacebookIcon size={25} round />
                          </FacebookShareButton>
                          <TelegramShareButton
                            url={window.location.href}
                            title={news?.title?.[language]}
                          >
                            <TelegramIcon size={25} round />
                          </TelegramShareButton>
                          <LinkedinShareButton
                            url={window.location.href}
                            title={news?.title?.[language]}
                            source={window.location.origin}
                          >
                            <LinkedinIcon size={25} round />
                          </LinkedinShareButton>
                        </div>
                      </div>
                    </div>
                    <div className="blog-details-banner">
                      <img
                        src={
                          news?.imageUrls?.[language]?.[
                            IMAGE_KEYS.COVER_IMAGE_GET
                          ]
                        }
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="blog-details-desc">
                      <RichTextHTMLParser
                        text={formatToJSON(
                          newsDescription ?? news?.description?.[language]
                        )}
                      />
                    </div>
                  </div>
                </Fetcher>
              </Col>
              <HorizontalTopNewsComponent />
            </Row>
          </Container>
        </section>
      </div>
    </Styles>
  );
};

export default NewsDetailsPage;
