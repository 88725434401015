import backendHttpRequest from "../../repositories/backendHttpRequest";
import { config } from "../../configs/repositoryConfig";

import { convertToQueryString } from "../../utilities/httpUtils";

export const getNews = async (filteredObject) => {
  const queryString = convertToQueryString(filteredObject);
  const resourceObject = {
    ...config.backendApiSettings.resources.getNews,
  };

  const endpoint = resourceObject.endpoint;
  resourceObject.endpoint = `${endpoint}${
    queryString ? `?${queryString}` : ""
  }`;

  const apiNews = await backendHttpRequest({
    resourceObject,
  });

  return apiNews;
};

export const getNewsById = async (id) => {
  const resourceObject = {
    ...config.backendApiSettings.resources.getNewsById,
  };
  const endpoint = resourceObject.endpoint;

  resourceObject.endpoint = endpoint.replace(":id", id);

  const apiNews = await backendHttpRequest({
    resourceObject,
  });

  return apiNews;
};
