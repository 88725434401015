import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Styles } from "./styles/conference.js";
import IconBox from "../../components/IconBox.jsx";
import { useLanguageDetector } from "../../hooks/useLanguageDetector";
import EnglishData from "../../data/conference/conference-en.json";
import RussianData from "../../data/conference/conference-rus.json";
import UzbekData from "../../data/conference/conference-uz.json";
import IconBoxData from "./data/iconbox.json";
import IconBoxDataRu from "./data/iconbox-ru.json";
import IconBoxDataUz from "./data/iconbox-uz.json";

const ConferenceProgram = () => {
  const [data] = useLanguageDetector(EnglishData, RussianData, UzbekData);
  const [iconboxData] = useLanguageDetector(
    IconBoxData,
    IconBoxDataRu,
    IconBoxDataUz
  );
  return (
    <Styles>
      <div className="main-wrapper conference-page">
        <section
          className="breadcrumb"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/event-6.jpg)`,
          }}
        >
          <Container>
            <Row>
              <Col md="12">
                <div className="breadcrumb-box">
                  <h2 className="breadcrumb-title">
                    PTU Inaugural International Conference on Pharmaceutics and
                    Life Sciences
                  </h2>
                  <h2 className="year">ICPL 2024</h2>
                  <p className="device">
                    Frontiers of research and higher education
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <IconBox data={iconboxData} />
      </div>
    </Styles>
  );
};

export default ConferenceProgram;
